export const cveIndication = [
  { id: 'yes', name: 'Yes', type: 'CVE Indication: Yes', key: 'cve-indication', active: false },
  { id: 'no', name: 'No', type: 'CVE Indication: No', key: 'cve-indication', active: false }
];

export const cisaKev = [
  { id: 'yes', name: 'Yes', type: 'CISA KEV: Yes', key: 'cisa-kev', active: false },
  { id: 'no', name: 'No', type: 'CISA KEV: No', key: 'cisa-kev', active: false }
];

export const riskScores = [
  { id: 'high', name: '80-100', type: 'Risk Score: High', key: 'risk-score', active: false },
  { id: 'medium', name: '40-79', type: 'Risk Score: Medium', key: 'risk-score', active: false },
  { id: 'low', name: '0-39', type: 'Risk Score: Low', key: 'risk-score', active: false }
];

export const currentStates = [
  { id: 'new', name: 'New', type: 'Current State: New', key: 'current-state', active: false },
  { id: 'changed', name: 'Changed', type: 'Current State: Changed', key: 'current-state', active: false },
  { id: 'unchanged', name: 'Unchanged', type: 'Current State: Unchanged', key: 'current-state', active: false },
  { id: 'removed', name: 'Removed', type: 'Current State: Removed', key: 'current-state', active: false }
]; 