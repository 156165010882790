import React, { useState, useEffect, useContext } from "react";
import { Col, Collapse, ListGroup, Button, Spinner } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Close } from "../../assets/images/Chevrons-left.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/chevron-blue-down.svg";
import DashedImage from "../../assets/images/Frame.png";
import LeftNavbar from "./LeftNavbar";
import BaseDropdown from "../../components/form/BaseDropdown";
import axios from "../../util/axios";
import { useParams } from "react-router-dom";
import { ScannerContext } from "../../components/ScannerContext";
import { isAdmin } from "../../util/constants";
import { getUser } from "../../util/userUtils";

const SideNavbar = ({ open, onToggle, targetId }) => {
  const { fetchTargets, dropdownItems, canAddTarget } = useContext(ScannerContext);
  const routeParams = useParams();
  const location = useLocation();
  const { pathname, search } = location;
  const [organizationList, setOrganizationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOrganizationOpen, setIsDropdownOrganizationOpen] = useState(false);
  const [isDropdownTargetOpen, setIsDropdownTargetOpen] = useState(false);

  const [selectedItemSearch, setSelectedItemSearch] = useState(null);
  const [selectedItemNoSearch, setSelectedItemNoSearch] = useState(null);

  const handleTargetId = (item) => {
    if (item?.scanTarget?.target_uuid) {
      targetId(item.scanTarget.target_uuid);
    }
  };
  const handleServiceChangeSearch = async (item) => {
    handleTargetId(item);
    setSelectedItemSearch((prev) => ({
      ...item,
      id: item?.scanTarget?.target_uuid,
      title: item.org_name,
    }));
  };

  const handleServiceChangeNoSearch = (item) => {
    setSelectedItemNoSearch(item);
  };
  const updateOrganization = async (organization_id) => {
    setIsLoading(true)
    try {
      await axios.put(
        `/admin/users/organization`,
        { organization_id },
        { headers: { "Content-Type": "application/json" } }
      );
      await fetchTargets();
    } catch (error) {
      console.error(
        "Error updating organization:",
        error.response?.data || error.message
      );
    }finally{
      setIsLoading(false)
    }
  };

  useEffect(() => {
    const fetchAndSetOrganization = async () => {
      try {
        const user = getUser();
        const userOrgId = user?.organization_id;
        let organizations = await fetchOrganization();
  
        if (userOrgId && organizations?.length > 0) {
          const defaultOrg = organizations.find((org) => org.id === userOrgId);
          if (defaultOrg) {
            setSelectedItemSearch({
              ...defaultOrg,
              title: defaultOrg.org_name,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching organizations:", error);
      }
    };
  
    fetchAndSetOrganization();
  }, []);
  const fetchOrganization = async () => {
    if (userLevel !== 9) return;
    setIsLoading(true);
    try {
      try {
        let res = await axios.get(`/all-organizations`);
        setOrganizationList(res.data);
      } catch (error) {
        console.log(error);
        throw error;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const userLevel = user?.member_level;

  useEffect(() => {
    if (selectedItemNoSearch !== null) {
      targetId(selectedItemNoSearch?.target_uuid);
    }
  }, [selectedItemNoSearch]);

  useEffect(() => {    
    if (dropdownItems?.length > 0) {
      if (routeParams?.id || routeParams?.target_id) {
        let item;
        let id = routeParams?.id || routeParams?.target_id;
        dropdownItems?.forEach((el) => {
          if (el.target_uuid == id) {
            item = el;
          }
        });
        setSelectedItemNoSearch(item);
      } else {
        setSelectedItemNoSearch(dropdownItems[0]);
      }
    }
  }, [dropdownItems, routeParams, search]);

  useEffect(() => {
    if (selectedItemNoSearch !== null) {
      targetId(selectedItemNoSearch?.target_uuid);
    }
  }, [selectedItemNoSearch]);

  useEffect(() => {
    const user = getUser();
    const userOrgId = user?.organization_id;

    if (userOrgId && organizationList?.length > 0) {
      const defaultOrg = organizationList.find((org) => org.id === userOrgId);

      if (defaultOrg) {
        setSelectedItemSearch({
          ...defaultOrg,
          title: defaultOrg.org_name,
        });
      }
    }
  }, [organizationList]);
  const init = async () => {
    fetchTargets();
  };

  useEffect(() => {
    init();
  }, [fetchTargets]);


  useEffect(() => {
    fetchOrganization();
  }, []);
  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" size="sm" />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Collapse
        in={open}
        dimension="width"
        className={`sidebar ${
          open ? "bounce-enter-active" : "bounce-leave-active"
        }`}
      >
        <Col
          lg="auto"
          md="12"
          className="p-0 sidebar-col border-end custom-sidebar"
        >
          {isAdmin() && (
            <Accordion
              defaultActiveKey={["0"]}
              alwaysOpen
              className="custom-accordion-admin sub-button"
            >
              <Accordion.Item eventKey="0" className="custom-accordion-item">
                <Accordion.Header
                  className="custom-accordion-header d-flex justify-content-between align-items-center"
                  style={{ color: "#fff" }}
                >
                  <div className="d-flex align-items-center">
                    <ArrowDown
                      height={25}
                      width={25}
                      className="accordion-arrow svg-white pe-2"
                    />
                    <span>ADMIN ORGANIZATION</span>
                  </div>

                  <Button className="btn-icon ms-auto" onClick={onToggle}>
                    <Close />
                  </Button>
                </Accordion.Header>

                <Accordion.Body className="accordion-body-main child-accordian-body">
                  <Accordion className="custom-accordion sub-accordion sub-body">
                    <div className="pt-2 pb-2 pe-0 ps-2">
                      <BaseDropdown
                        key={selectedItemSearch?.target_uuid}
                        items={organizationList}
                        selectedItem={selectedItemSearch}
                        onSelect={handleServiceChangeSearch}
                        canAddTarget={true}
                        isDropdownOpen={isDropdownOrganizationOpen}
                        setIsDropdownOpen={setIsDropdownOrganizationOpen}
                        userLevel={userLevel}
                        hasSearch={true}
                        updateOrganization={updateOrganization}
                      />
                    </div>
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}

          <ListGroup className="list-group border-0 rounded-0 text-sm-start align-items-sm-start  justify-content-between custom-list-group w-100">
            <div
              className="d-flex justify-content-between align-items-center w-100"
              style={{
                marginTop: !isAdmin() ? "10px" : "-5px",
              }}
            >
              <Link
                to="/"
                className={`custom-list-item d-flex justify-content-between align-items-center ${
                  pathname === "/" ? "active" : ""
                }`}
              >
                <span className="sidebar-top-heading">TARGET SELECTION</span>
              </Link>
              {!isAdmin() && (
                <Button
                  className="btn-icon ms-auto mr-2"
                  onClick={onToggle}
                  style={{
                    position: "relative",
                    right: "19px",
                  }}
                >
                  <Close />
                </Button>
              )}
            </div>
          </ListGroup>

          <div className="p-2">
            {dropdownItems.length > 0 ? (
              <BaseDropdown
                key={selectedItemSearch?.target_uuid}
                items={dropdownItems}
                selectedItem={selectedItemNoSearch}
                onSelect={handleServiceChangeNoSearch}
                isDropdownOpen={isDropdownTargetOpen}
                setIsDropdownOpen={setIsDropdownTargetOpen}
                userLevel={userLevel}
                canAddTarget={canAddTarget}
                hasSearch={false}
              />
            ) : (userLevel === 0 && dropdownItems.length === 0) ||
              userLevel === 1 ||
              userLevel === 9 ? (
              <Link
                to="/assets-inventory/new"
                className="add-button header-title"
              >
                <i className="bi bi-plus-circle mx-2"></i>Add New Target
              </Link>
            ) : null}
          </div>

          <Accordion
           defaultActiveKey={["0","1"]}  
            alwaysOpen
            className="custom-accordion sub-button"
          >
            <Accordion.Item eventKey="0" className="custom-accordion-item">
              <Accordion.Header className="custom-accordion-header">
                <ArrowDown
                  height={25}
                  width={25}
                  className="accordion-arrow pe-2"
                />{" "}
                SCANNERS IN ACTION
              </Accordion.Header>
              <Accordion.Body className="accordion-body-main child-accordian-body">
                <Accordion className="custom-accordion sub-accordion sub-body">
                  <LeftNavbar open={true} selectedItem={selectedItemNoSearch} />
                </Accordion>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="1"
              className="custom-accordion-item war-room-accordian mt-3"
            >
                <LeftNavbar
                  open={open}
                  isWarRoom={true}
          
                />
            </Accordion.Item>
          </Accordion>
        </Col>
      </Collapse>
    </React.Fragment>
  );
};

export default SideNavbar;
