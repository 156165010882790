import React, { useState, useEffect, useRef, useContext } from "react";
import {
  FormGroup,
  FormControl,
  Button,
  Container,
  Modal,
} from "react-bootstrap";
import axios from "../../util/axios";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import core from "../../util/core";
import { ReactComponent as Delete } from "../../assets/images/delete.svg";
import { ScannerContext } from "../../components/ScannerContext";
import TermsConditionsModal from "../../auth/register/TermsConditionsModal";
import { locationToPageName } from "../../helpers";
import { sendEvent } from "../../util/analytics";
import { showToast, showUpdateToast } from "../../util/toasts";
import "./CreateTargetStyle.scss";
import { Navbar, Nav } from "react-bootstrap";
import TargetInput from "./TargetInput";
import FileUpload from "./FileUpload";
import { isAdmin, isNormalUser } from "../../util/constants";
import ScanTypeSelector from "./ScanType";
import DropdownWrapper from "../../components/Dropdown/DropdownWrapper";
import { colors } from "../../util/colors";
import { getUserRole } from "../../util/userUtils";

const services = {
  0: "full",
  1: "demo",
  4: "selected",
};

const CreateTarget = ({ scan }) => {
  const navigate = useNavigate();
  const [showTermsModal, setShowTermsModal] = useState(false);
  const { fetchTargets, dropdownItems } = useContext(ScannerContext);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedService, setSelectedService] = useState(0);
  const [isScanning, setIsScanning] = useState(false);
  const [hosts, setHosts] = useState([]);
  const [orgDetails, setOrgDetails] = useState(null);
  const [servicesResponse, setServicesResponse] = useState([]);
  const [isDefault, setIsDefault] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  const targetNameInputRef = useRef(null);
  const hostInputRef = useRef(null);
  const ipsLimitRef = useRef(0);
  const [allTargets, setAllTargets] = useState([]);
  const location = useLocation();
  const [isActiveLink, setIsActiveLink] = useState("Target Input");
  const [fileRecord, setFileRecord] = useState([]);
  const API_ENDPOINT_NODE = id ? `/targets/${id}` : "targets";
  const userRole = getUserRole();
  const user = JSON.parse(localStorage.getItem("user"));
  const isViewerUser = user?.member_level == 0;
  const isEditorUser = user?.member_level == 2;

  const schema = Yup.object().shape({
    title: Yup.string()
      .required("Target Name is required")
      .test("is-valid-title", "Title cannot contain only symbols", (value) =>
        /^[\p{L}\p{N}]+[\p{L}\p{N}\s@#$%&*!,.-]*$/u.test(value)
      )
      .test("is-unique-title", "Target Name already exists", function (value) {
        if (scan?.scanInfo?.id) return true;
        return !allTargets.some((item) => item.title === value);
      }),
    scan_type: Yup.string().required("Scan type is required"),
    host: Yup.string()
      .test(
        "is-host-required",
        "Assets are required",
        (value) =>
          isActiveLink === "File Upload" || hosts?.length > 0 || value !== ""
      )
      .test(
        "isValidHost",
        "Invalid Assets",
        (value) => !value || core.isValidHost(value)
      ),
  });

  const initialValues = {
    title: scan?.scanInfo?.scan_name
      ? scan?.scanInfo?.scan_name
      : isDefault
      ? orgDetails?.org_name
      : "",
    host: "",
    hosts: scan?.scanInfo?.hosts_list?.split(",")?.filter((host) => host) || [],
    scan_type: 0,
    ip_limit: scan?.scanInfo?.ip_limit || 0,
  };
  useEffect(() => {
    if (scan && scan.scanInfo && scan.scanInfo.hosts_list) {
      initialValues["title"] = scan?.scanInfo?.scan_name;
      const initialHosts = scan.scanInfo.hosts_list
        ?.split(",")
        ?.filter((host) => host);
      setHosts(initialHosts);
    } else {
      setHosts([]);
    }
  }, [scan]);

  const fetchAllTargets = async () => {
    try {
      const { data } = await axios.get(`targets?page=1&pageSize=1000`);
      setAllTargets(data?.items);
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const getServiceData = async () => {
    try {
      const { data } = await axios.get(`/scanServices`);
      if (data?.data) {
        setServicesResponse(data.data);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };
  useEffect(() => {
    fetchAllTargets();
    getServiceData();
  }, []);

  const getUser = () => {
    const userData = localStorage.getItem("user");
    return userData ? JSON.parse(userData) : {};
  };

  const handleDeclineTerms = () => {
    setShowTermsModal(false);
  };

  const handleAcceptTerms = () => {
    setShowTermsModal(false);
    setIsTermsAccepted(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleDeleteClick = () => {
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`/targets/${scan?.scanInfo?.id}`);
      await fetchTargets();
      const defaultTargetId = allTargets?.filter(
        (el) => el?.default_scan == 1
      )?.[0];
      navigate(`/dashboard/${defaultTargetId?.target_uuid}`);
      showToast(
        "Target deleted successfully!",
        "success",
        "top-center",
        "light"
      );
      setShowModal(false);
    } catch (error) {
      showToast("Failed to delete the target!", "error", "top-center", "light");
      setShowModal(false);
    }
  };

  const getAllOrganizations = async () => {
    try {
      const { data } = await axios.get("organizations", {
        params: { page: 1, pageSize: 1000 },
      });
      setOrgDetails(
        data?.items?.filter((el) => el.id === getUser().organization_id)?.[0]
      );
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const addHost = (e, onSuccess) => {
    const input = e.target.value.trim();
    const hostsArray = input.split(",").map((host) => host.trim());
    const cleanedDomains = hostsArray.map((domain) => cleanDomainNew(domain));
    if (cleanedDomains.length < 1) {
      showToast(`Asset Required`, "error", "top-center", "colored");
      return;
    }
    const uniqueDomains = new Set(cleanedDomains);
    if (uniqueDomains.size !== cleanedDomains.length) {
      showToast(`Assets already added.`, "error", "top-center", "colored");
      return;
    }
    onSuccess();
  };

  useEffect(() => {
    if (!scan?.scanInfo?.id) {
      targetNameInputRef.current?.focus();
    } else {
      hostInputRef.current?.focus();
    }
  }, [scan]);

  const cleanDomainNew = (url) => {
    const ipRangePattern = /^\d{1,3}(\.\d{1,3}){3}-\d{1,3}(\.\d{1,3}){3}$/;
    const rangeIp = url;

    if (ipRangePattern.test(url)) {
      return url;
    }

    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = "http://" + url;
    }

    try {
      const newUrl = new URL(url);
      let hostname = core.validCidrIp(rangeIp) ? rangeIp : newUrl.hostname;

      if (hostname.startsWith("www.")) {
        hostname = hostname.slice(4);
      }

      if (!hostname || hostname === "http" || hostname === "https") {
        return null;
      }

      return hostname;
    } catch (e) {
      return null;
    }
  };

  const getUniqueHosts = (hosts_, values_) => {
    const updatedHosts = hosts_?.map((el) => el);
    const hostsArray = values_.host?.split(",")?.map((host) => host.trim());
    const cleanedDomains = hostsArray?.map((domain) => cleanDomainNew(domain));
    if (cleanedDomains?.length > 0) {
      cleanedDomains.map((el) => {
        if (!hosts_?.includes(el)) {
          updatedHosts.push(el);
        }
      });
    }
    return updatedHosts;
  };

  const onAddingAssets = (values, helpers) => {
    setHosts(getUniqueHosts(hosts, values));
  };

  const handleSubmit = async (values, helpers) => {
    if (isActiveLink === "File Upload") {
      if (fileRecord?.length > 0) {
        let payload = {
          ip_limit: values.ip_limit || 0,
          scan_type: values.scan_type,
          title: values.title,
          csvOutput: fileRecord,
        };
        if (!id) {
          if (selectedOptions?.length == 0 && values.scan_type == 4) {
            showToast(
              "Select at least one scan",
              "error",
              "top-center",
              "light"
            );
            return;
          } else {
            payload["selected_services"] = selectedOptions.join(",");
          }
        }
        if (isDefault) {
          payload["default_scan"] = 1;
        }
        const toastId = showToast(
          id ? "Updating Target..." : "Creating Target...",
          "info",
          "top-center",
          "light",
          {
            autoClose: false,
            isLoading: true,
          }
        );
        try {
          let endpoint = id
            ? `/targets/${id}/editFromCSV`
            : "/targets/createFromCSV";
          const { data } = await axios.post(endpoint, payload);
          await fetchTargets();
          showUpdateToast(
            toastId,
            id ? (
              <div>
                <b>Assets Added Successfully</b>
                <br />
                Scanning for these assets will occur during the next automated
                scan cycle
              </div>
            ) : (
              "Target Created Successfully"
            ),
            "success",
            false
          );
          const pageName = locationToPageName(location);
          const obj = {
            pageName,
            result: "target created",
            scanType: services[values.scan_type],
          };
          if (obj.scanType === services[4]) obj.selected = selectedOptions;
          sendEvent("buttonClick", obj);
          navigate(`/dashboard/${data?.data?.target_uuid}`);
        } catch (err) {
          showUpdateToast(
            toastId,
            err?.response?.data?.message,
            "error",
            false
          );
        }
      } else {
        showToast("Please upload the file", "error", "top-center", "light");
      }
    } else {
      if (values.host.trim()) {
        addHost({ target: { value: values.host } }, () => {
          onAddingAssets(values, helpers);
        });
      }
      values.hosts = getUniqueHosts(hosts, values)?.filter((el) => el);
      if (!id) {
        if (selectedOptions?.length == 0 && values.scan_type == 4) {
          showToast("Select at least one scan", "error", "top-center", "light");
          return;
        } else {
          values.selected_services = selectedOptions.join(",");
        }
      }
      values.host = values.hosts?.join(",");

      if (isDefault) {
        values["default_scan"] = 1;
      }
      setIsScanning(true);
      try {
        const { data } = await axios.post(API_ENDPOINT_NODE, values);
        await fetchTargets();

        showToast(
          id ? (
            <div>
              <b>Assets Added Successfully</b>
              <br />
              Scanning for these assets will occur during the next automated
              scan cycle
            </div>
          ) : (
            "Target Created Successfully"
          ),
          "success",
          "top-center",
          "light"
        );

        const pageName = locationToPageName(location);
        const obj = {
          pageName,
          result: "target created",
          scanType: services[values.scan_type],
        };
        if (obj.scanType === services[4]) obj.selected = selectedOptions;
        sendEvent("buttonClick", obj);
        helpers.setFieldValue("host", "");
        navigate(`/dashboard/${data?.data?.target_uuid}`);
        setIsScanning(false);
      } catch (err) {
        showToast(err?.response?.data?.message, "error", "top-center", "light");
        helpers.setFieldValue("host", "");
        setIsScanning(false);
      }
    }
  };

  useEffect(() => {
    if (allTargets?.length === 0 || scan?.scanInfo?.default_scan == 1) {
      setIsDefault(true);
    } else {
      setIsDefault(false);
    }
  }, [allTargets, scan?.scanInfo]);

  useEffect(() => {
    getAllOrganizations();
  }, []);

  useEffect(() => {
    if (dropdownItems.length > 0 && isNormalUser() && !id) {
      navigate("/");
    }
  }, [dropdownItems, navigate]);

  useEffect(() => {
    if (userRole === "viewer") {
      navigate("/");
    }
  }, [userRole, navigate]);

  return (
    <>
      <div className="create-target-main">
        <div className="create-target-container">
          <div className="create-target-form">
            <Formik
              enableReinitialize={true}
              validationSchema={schema}
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validateOnMount={false}
              context={{ allTargets }}
              render={({ values, errors, setFieldValue }) => {
                return (
                  <Form>
                    {!isScanning && (
                      <div>
                        <div className="col-md-6 hide-invalid-icon-field">
                          <Field
                            name="title"
                            render={({
                              field,
                              form: { touched, errors },
                              meta,
                            }) => (
                              <FormGroup controlId="title">
                                <label>
                                  Target Name
                                  {isDefault && (
                                    <tag className="tag-default">
                                      Organization
                                    </tag>
                                  )}
                                </label>

                                <FormControl
                                  type={"text"}
                                  size="lg"
                                  value={field.value}
                                  onChange={field.onChange}
                                  placeholder="Enter the target name"
                                  className={
                                    meta.touched && meta.error
                                      ? "is-invalid text-white fw-lighter"
                                      : "text-white fw-lighter"
                                  }
                                  ref={targetNameInputRef}
                                  autoFocus
                                  {...field}
                                />
                                {meta.touched && meta.error ? (
                                  <div className="invalid-feedback">
                                    {meta.error}
                                  </div>
                                ) : null}
                              </FormGroup>
                            )}
                          />
                        </div>
                        <p className="add-asset">Add Asset</p>
                        <div>
                          Armory will automatically scan the provided assets to
                          identify related root domains, hostnames, and IP
                          addresses
                        </div>
                        <Navbar className="border-bottom mt-4 w-fit-content">
                          <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="">
                              <Link
                                className={`disable-link-color nav-text text-white ${
                                  isActiveLink === "Target Input"
                                    ? "active-nav-text"
                                    : ""
                                }`}
                                onClick={() => {
                                  setIsActiveLink("Target Input");
                                }}
                              >
                                Target's Input
                              </Link>
                              <Link
                                style={{ marginLeft: "1.5rem" }}
                                className={`disable-link-color nav-text text-white ${
                                  isActiveLink === "File Upload"
                                    ? "active-nav-text"
                                    : ""
                                }`}
                                onClick={() => {
                                  setIsActiveLink("File Upload");
                                }}
                              >
                                File Upload
                              </Link>
                            </Nav>
                          </Navbar.Collapse>
                        </Navbar>

                        {isActiveLink === "Target Input" && (
                          <TargetInput
                            data={{ hosts, values, scan, allTargets }}
                            setData={{ setHosts, setFieldValue }}
                            cleanDomainNew={cleanDomainNew}
                            addHost={addHost}
                          />
                        )}

                        {isActiveLink === "File Upload" && (
                          <FileUpload setFileRecord={setFileRecord} />
                        )}

                        {!scan?.scanInfo?.id && !isNormalUser() && (
                          <div
                            className="col-md-12 pb-2"
                            style={{
                              marginTop: "-15px",
                              paddingBottom: "0px !important",
                            }}
                          >
                            <FormGroup controlId="scan_type">
                              <ScanTypeSelector
                                selectedService={selectedService}
                                setSelectedService={setSelectedService}
                                setFieldValue={setFieldValue}
                              />
                            </FormGroup>

                            {!isNormalUser() &&
                              servicesResponse.length > 0 &&
                              selectedService === 4 && (
                                <div className="row">
                                  {" "}
                                  <div className="col-md-7 custom_dropdown">
                                    <DropdownWrapper
                                      options={servicesResponse}
                                      setSelectedOptions={setSelectedOptions}
                                      selectedOptions={selectedOptions}
                                    />
                                  </div>
                                </div>
                              )}
                          </div>
                        )}

                        {isAdmin() && (
                          <div
                            className="row"
                            style={{
                              marginTop: !id ? "-5px" : "-15px",
                              paddingBottom: "9px",
                            }}
                          >
                            {" "}
                            <div className="col-md-12">
                              {" "}
                              <Field
                                name="ip_limit"
                                render={({
                                  field,
                                  form: { touched, errors },
                                  meta,
                                }) => (
                                  <FormGroup controlId="title">
                                    <label>IPs Limitation</label>

                                    <FormControl
                                      type={"number"}
                                      size="lg"
                                      value={field.value}
                                      onChange={field.onChange}
                                      placeholder="Select the IPs amount you want to scan"
                                      className={
                                        meta.touched && meta.error
                                          ? "is-invalid text-white fw-lighter"
                                          : "text-white fw-lighter"
                                      }
                                      ref={ipsLimitRef}
                                      autoFocus
                                      disabled={selectedService === 1}
                                      onFocus={(e) =>
                                        e.target.addEventListener(
                                          "wheel",
                                          (event) => event.preventDefault()
                                        )
                                      }
                                      onBlur={(e) =>
                                        e.target.removeEventListener(
                                          "wheel",
                                          (event) => event.preventDefault()
                                        )
                                      }
                                      onKeyDown={(e) => {
                                        if (
                                          !/[0-9]/.test(e.key) &&
                                          e.key !== "Backspace" &&
                                          e.key !== "Delete" &&
                                          e.key !== "ArrowLeft" &&
                                          e.key !== "ArrowRight"
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      {...field}
                                    />
                                    {meta.touched && meta.error ? (
                                      <div className="invalid-feedback">
                                        {meta.error}
                                      </div>
                                    ) : null}
                                  </FormGroup>
                                )}
                              />
                            </div>
                          </div>
                        )}
                        <hr className="mt-2" />
                        <div className="mt-5 box p-1">
                          <div
                            className="box-text"
                            style={{
                              marginLeft: scan?.scanInfo?.id ? "10px" : "0px",
                            }}
                          >
                            {scan?.scanInfo?.id ? (
                              <Field name="agreeTerms">
                                {({ field, form }) => (
                                  <FormGroup
                                    controlId="agreeTerms"
                                    className="my-1  w-100 ms-2 terms-cond"
                                  >
                                    <input
                                      type="checkbox"
                                      id="flexCheckDefault"
                                      checked={isTermsAccepted}
                                      onChange={() =>
                                        setIsTermsAccepted(!isTermsAccepted)
                                      }
                                      className={`custom-checkbox${
                                        form.touched.agreeTerms &&
                                        form.errors.agreeTerms
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      readOnly
                                    />
                                    <span className="scan-content-text ms-2">
                                      I have the rights and authorizations to
                                      scan this target and I agree to the
                                      <u
                                        style={{ color: colors["border-blue"] }}
                                      >
                                        <a
                                          style={{
                                            color: colors["border-blue"],
                                            cursor: "pointer",
                                          }}
                                          className="ms-1"
                                          onClick={() =>
                                            setShowTermsModal(true)
                                          }
                                        >
                                          Terms of Services
                                        </a>
                                      </u>
                                    </span>
                                    {form.touched.agreeTerms &&
                                      form.errors.agreeTerms && (
                                        <div className="invalid-feedback d-block">
                                          {form.errors.agreeTerms}
                                        </div>
                                      )}
                                  </FormGroup>
                                )}
                              </Field>
                            ) : (
                              <>
                                <Field name="agreeTerms">
                                  {({ field, form }) => (
                                    <FormGroup
                                      controlId="agreeTerms"
                                      className="my-1 w-100 ms-1 terms-cond"
                                    >
                                      <input
                                        type="checkbox"
                                        id="flexCheckDefault"
                                        checked={isTermsAccepted}
                                        onChange={() =>
                                          setIsTermsAccepted(!isTermsAccepted)
                                        }
                                        className={`custom-checkbox ${
                                          form.touched.agreeTerms &&
                                          form.errors.agreeTerms
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        readOnly
                                      />
                                      <span className="scan-content-text ms-2">
                                        I have the rights and authorizations to
                                        scan this target and I agree to the
                                        <u
                                          style={{
                                            color: colors["border-blue"],
                                          }}
                                        >
                                          <a
                                            style={{
                                              color: colors["border-blue"],
                                              cursor: "pointer",
                                            }}
                                            className="ms-1"
                                            onClick={() =>
                                              setShowTermsModal(true)
                                            }
                                          >
                                            Terms of Services
                                          </a>
                                        </u>
                                      </span>
                                      {form.touched.agreeTerms &&
                                        form.errors.agreeTerms && (
                                          <div className="invalid-feedback d-block">
                                            {form.errors.agreeTerms}
                                          </div>
                                        )}
                                    </FormGroup>
                                  )}
                                </Field>
                              </>
                            )}
                          </div>

                          <TermsConditionsModal
                            show={showTermsModal}
                            onHide={handleDeclineTerms}
                            onContinue={handleAcceptTerms}
                            onDecline={handleDeclineTerms}
                            isAfterRegister={true}
                          />
                        </div>

                        <div className="d-flex justify-content-center mt-5">
                          {scan?.scanInfo?.id ? (
                            <Button
                              type="submit"
                              variant="secondary"
                              className="btn btn-primary w-50 scan-button"
                              style={{ background: "#767272" }}
                              id="singup-btn"
                              disabled={
                                Object.entries(errors)?.length > 0 ||
                                (hosts.length <= 0 &&
                                  !core.isValidHost(values?.host)) ||
                                !isTermsAccepted
                              }
                            >
                              Save Changes
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              className="btn btn-primary w-50 scan-button"
                              id="singup-btn"
                              disabled={
                                Object.entries(errors)?.length > 0 ||
                                (isActiveLink == "Target Input" &&
                                  hosts.length <= 0 &&
                                  !core.isValidHost(values?.host)) ||
                                !isTermsAccepted
                              }
                            >
                              Create Target
                            </Button>
                          )}
                        </div>
                        {!isDefault &&
                          scan?.scanInfo?.id &&
                          !isViewerUser &&
                          !isEditorUser && (
                            <div
                              className="delete-btn mt-3"
                              onClick={() => handleDeleteClick()}
                            >
                              <span>Delete Target</span>
                              <Delete />
                            </div>
                          )}
                      </div>
                    )}
                  </Form>
                );
              }}
            />
          </div>
          {isScanning && (
            <Container className="dos-container p-0 bg-black mt-3  mb-3">
              <Container className="px-3 pt-3 pb-5">
                <div className="scanning-content">
                  <p className="active-text">Scanning for domains...</p>
                  <p className="active-text">
                    We'll let you know when scanning is complete
                  </p>
                  <p className="header-title">Host Names:</p>
                  {hosts.map((host, index) => (
                    <p key={index} className="active-text">
                      {host}
                    </p>
                  ))}
                </div>
              </Container>
            </Container>
          )}
        </div>
      </div>
      <Modal show={showModal} centered onHide={handleClose} className="w-70">
        <Modal.Header className="target-modal">
          <div className="d-flex flex-column mt-4 align-items-center justify-content-center w-100">
            <div>
              <Modal.Title>
                <h3>Are you sure you want to delete this target?</h3>
              </Modal.Title>
            </div>
            <div className="d-flex align-items-center justify-content-center gap-3 mt-5 mb-5 w-100">
              <Button className=" target-button fs-12 " onClick={handleClose}>
                Cancel
              </Button>
              <Button
                className=" target-button fs-12"
                onClick={handleConfirmDelete}
              >
                Yes Delete
              </Button>
            </div>
          </div>
        </Modal.Header>
      </Modal>
    </>
  );
};

export default CreateTarget;
