import React, { Component } from 'react';

import CallView from './views/call-view/Index';
import InitialView from './views/initial-view/Index';
import IncomingCallView from './views/incoming-call-view/Index';
import axios from "../../util/axios";

import { CLASS_PREFIX } from '../../util/constants';
import STATES from '../../util/states';
import ACTIONS from '../../util/actions';
import JsSIP from 'jssip';

class ReactDialpad extends Component {
  constructor(props) {
    super();
    let contact = {};
    console.log('number', props)
    if (props?.number) {
      console.log('i am here with number', props.number)
      contact = { number: props.number }
    }
    this.state = {
      state: STATES.DEFAULT,
      timer: null,
      startTime: null,
      contact: contact,
      ua: null
    };
    this.ua = null;
    this.session = null;
    // set UA
    this.setState({ ua: this.setupSIP() });


    this.onCallPressed = this.onCallPressed.bind(this);
    this.onCallEndPressed = this.onCallEndPressed.bind(this);
    this.onCallReplyPressed = this.onCallReplyPressed.bind(this);
    this.onCallRejectPressed = this.onCallRejectPressed.bind(this);

    this.setRinging = this.setRinging.bind(this);
    this.setOnCall = this.setOnCall.bind(this);
    this.setIncomingCall = this.setIncomingCall.bind(this);
    this.endCall = this.endCall.bind(this);
  }


  doAddStream(stream) {
    try {
      this.audioElement.srcObject = stream
      this.audioElement.play()
    } catch (e) {
      console.log('error', e)
      alert('Unable to start Audio Stream...')
    }
  }
  addStream() {
    // this.session.connection.addEventListener('addstream', (event) => {
    //   console.log('event', event)
    //   this.audioElement.srcObject = event.stream
    //   this.audioElement.play()
    // })
    // this.session.connection.addEventListener('addstream', (event) => {
    //   console.log('event', event)
    //   this.audioElement.srcObject = event.stream
    //   this.audioElement.play()
    // })
    let THAT = this;
    if (this.session.connection.addTrack !== undefined) {
      this.session.connection.ontrack = ev => {
        ev.streams.forEach(stream => THAT.doAddStream(stream));
      }
    } else {
      this.session.connection.onaddstream = ev => {
        THAT.doAddStream(ev.stream);
      }
    }

  }
  async setupSIP(){
    // Create our JsSIP instance and run it:

    const { data } = await axios.get("/spoofingapi");
    //console.log('data', data);
    var socket = new JsSIP.WebSocketInterface(data.WebSocketInterface);
    var configuration = {
      sockets: [socket],
      uri: data.uri,
      password: data.password
    };

    var ua = new JsSIP.UA(configuration);


    let THAT = this;
    ua.on('newRTCSession', function (ev) {
      console.log('my session', THAT.state)
      var newSession = ev.session;

      if (THAT.session) { // hangup any existing call
        THAT.session.terminate();
      }
      THAT.session = newSession;

      var completeSession = function () {
        THAT.session = null;
      };


      if (THAT.session.direction === 'outgoing') {
        console.log('stream outgoing  -------->');
        THAT.session.on('connecting', function () {
          console.log('CONNECT');
        });
        THAT.session.on('peerconnection', function (e) {
          console.log('1accepted');
          THAT.addStream();
        });
        THAT.session.on('ended', completeSession);
        THAT.session.on('failed', completeSession);
        THAT.session.on('accepted', function (e) {
          console.log('accepted')
        });
        THAT.session.on('confirmed', function (e) {
          console.log('CONFIRM STREAM');
        });

      };


    });


    ua.start();


    ua.on("registered", function () {
      //alert("extension is registered")

      console.log("extension is registered")


    });


    ua.on("unregistered", function () {
      console.log("extension is unregistered")
    });

    // ua.on('newRTCSession', function (e) { console.log(e); });
    this.ua = ua;
    return ua;
  }
  attachSinkId(sinkId) {
    let element = this.audioElement;
    if (typeof element.sinkId !== 'undefined') {
      element.setSinkId(sinkId)
        .then(() => {
          console.log(`Success, audio output device attached: ${sinkId}`);

        })
        .catch(error => {
          let errorMessage = error;
          if (error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
          console.error(errorMessage); alert(errorMessage)
          // Jump back to first output device in the list as it's the default.

        });
    } else {
      console.warn('Browser does not support output device selection.');

    }
  }

  dial(numberToDial, cid, eventHanlers) {

    var dial_string = numberToDial + "_" + cid;

    // this.attachSinkId(this.audioElement,)
    var options = {
      'eventHandlers': eventHanlers,
      'mediaConstraints': { 'audio': true, 'video': false }

    };
    this.setState({contact: { ...this.state.contact, ...{ from: cid } }})
    //ua.call('972524045047', options)
    this.ua.call(dial_string, options)



    this.addStream();




    console.log('session', this.state.session)
    this.setRinging();

  }

  componentDidUpdate(prevProps, prevState) {
    const { onStateChanged } = this.props;
    console.log('propsy', prevProps.number, this.props?.number, this.state.contact.number)
    if(prevProps?.number != this.props?.number){
      this.setState({contact: { ...this.state.contact, ...{ number: this.props.number } }})
    }
    if (prevState.state !== this.state.state) {
      if (onStateChanged) {
        onStateChanged(this.state.state);
      }
    }
  }



  onCallPressed(number) {
    const { onActionInvoked } = this.props;
    console.log('prpos', this.props)
    this.setState({
      state: STATES.CALLING,
      contact: {
        ...this.state.contact, ...{
          avatar: null,
          name: number, // TODO: Replaced with the real contact name in provided addressbook
          number: number
        }
      }
    });

    if (onActionInvoked) {
      onActionInvoked(ACTIONS.CALL_STARTED, { number });
    }
  }

  onCallEndPressed() {
    const { onActionInvoked } = this.props;

    this.setState({
      state: STATES.ENDING
    });

    if (onActionInvoked) {
      onActionInvoked(ACTIONS.CALL_ENDED);
    }
  }

  onCallReplyPressed() {
    if (this.state.state !== STATES.INCOMING_CALL) {
      throw new Error('Call can be replied only on INCOMING_CALL state');
    }
    const { onActionInvoked } = this.props;

    this.setState({
      state: STATES.REPLYING
    });

    if (onActionInvoked) {
      onActionInvoked(ACTIONS.CALL_REPLIED);
    }
  }

  onCallRejectPressed() {
    if (this.state.state !== STATES.INCOMING_CALL) {
      throw new Error('Call can be rejected only on INCOMING_CALL state');
    }
    const { onActionInvoked } = this.props;

    this.setState({
      state: STATES.REJECTING
    });

    if (onActionInvoked) {
      onActionInvoked(ACTIONS.CALL_REJECTED);
    }
  }

  setOnCall() {
    console.log('data ', this.state)
    if (this.state.state === STATES.RINGING ||
      this.state.state === STATES.REPLYING ||
      this.state.state === STATES.CALLING) {
      this.setState({
        state: STATES.ON_CALL,
        startTime: Date.now()
      });

      this.timer = setInterval(() => {
        this.setState({
          timer: Math.floor((Date.now() - this.state.startTime) / 1000)
        });
      }, 1000);
    } else {
      throw new Error('ON_CALL state can only be navigated from RINGING, REPLYING or CALLING');
    }
  }

  setRinging() {
    if (this.state.state !== STATES.CALLING) {
      throw new Error('RINGING state can only be navigated CALLING');
    }
    this.setState({
      state: STATES.RINGING
    });
  }

  setIncomingCall(contact) {
    if (this.state.state !== STATES.DEFAULT) {
      throw new Error('Multiple call not supported.');
    }
    this.setState({
      contact,
      state: STATES.INCOMING_CALL
    });
  }

  endCall() {
    if (this.timer) {
      clearInterval(this.timer);
    }

    this.setState({
      state: STATES.DEFAULT,
      startTime: null,
      timer: null,
      // contact: {}
    });
  }

  render() {
    const { style } = this.props;
    let view;

    if (this.state.state === STATES.DEFAULT) {
      view = <InitialView
        contact={this.state.contact}
        onCallPressed={this.onCallPressed}
      />;
    } else if (this.state.state === STATES.INCOMING_CALL ||
      this.state.state === STATES.REPLYING ||
      this.state.state === STATES.REJECTING) {
      view = <IncomingCallView
        contact={this.state.contact}
        state={this.state.state}
        onCallRejectPressed={this.onCallRejectPressed}
        onCallReplyPressed={this.onCallReplyPressed}
      />;
    } else {

      view = <CallView
        contact={this.state.contact}
        state={this.state.state}
        timer={this.state.timer}
        onCallEndPressed={this.onCallEndPressed}
      />;
    }

    return (
      <div className={`${CLASS_PREFIX}-root`} style={style}>
        {view}
        <audio ref={(audio) => { this.audioElement = audio }} id="audio-element"></audio>
      </div >
    );
  }
}

export default ReactDialpad;
