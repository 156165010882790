import React, { useState, useEffect, useRef } from "react";
import { Tab } from "react-bootstrap";
import BaseInput from "../form/BaseInput";
import BaseCheckbox from "../form/BaseCheckbox";
import { ReactComponent as Search } from "../../assets/images/search.svg";
import axios from "../../util/axios.js";
import { useParams } from "react-router-dom";

const TabPaneContent = ({
  eventKey,
  activeFilters,
  data,
  onDomainChange,
  isBackendProcess,
  title,
}) => {
  const routeParams = useParams();
  const [searchValue, setSearchValue] = useState("");
  const [displayData, setDisplayData] = useState([]);
  const [checkedItems, setCheckedItems] = useState(new Map());
  const [userAddedOptions, setUserAddedOptions] = useState([]);
  const searchTimeoutRef = useRef(null);

  useEffect(() => {
    if (isBackendProcess) {
      if (searchValue.length > 1) {
        setDisplayData(userAddedOptions);
      } else {
        setDisplayData(data);
      }
    } else {
      let newCheckedItems = new Map(checkedItems);
      data?.forEach((el) => {
        const hasActiveFilter = activeFilters?.some(
          (ol) => ol.eventKey === el.key && ol.name === el.name
        );
        newCheckedItems.set(el.name, hasActiveFilter);
      });
      setCheckedItems(newCheckedItems);
      const filteredData =
        searchValue.trim() === ""
          ? data
          : data.filter((item) =>
              item.name.toLowerCase().includes(searchValue.toLowerCase())
            );
      setDisplayData(filteredData);
    }
  }, [searchValue, activeFilters, isBackendProcess, userAddedOptions]);

  const handleCheckboxChange = (item) => {
    const currentState = checkedItems.get(item.name);
    const newState = !currentState;
    setCheckedItems(new Map(checkedItems.set(item.name, newState)));
    onDomainChange(item, newState);
  };

  const clearAllCheckboxes = () => {
    let newCheckedItems = new Map();
    displayData.forEach((item) => {
      newCheckedItems.set(item.name, false);
      onDomainChange(item, false, "clear");
    });
    setCheckedItems(newCheckedItems);
  };

  const selectAllCheckbox = () => {
    onDomainChange(false, false, "selectAll", displayData);
  };

  const handleCustomValueChange = (e) => {
    setSearchValue(e.target.value);
    if (isBackendProcess && e.target.value?.length > 1) {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
      searchTimeoutRef.current = setTimeout(() => {
        fetchSearchedValue(e.target.value);
      }, 800);
    }
  };

  const fetchSearchedValue = async (value) => {
    try {
      const { data } = await axios.get(
        `/filter/${routeParams?.id}/assetInventory?service=${title}&search=${value}`,
        {
          version: "v2",
        }
      );
      if (Array.isArray(data?.data) && data?.data?.length > 0) {
        const modifiedData = data?.data?.map((el) => {
          return {
            active: false,
            id: el?.value,
            key: title,
            name: el?.label,
            type: `${title}: ${el?.label}`,
          };
        });
        setUserAddedOptions(modifiedData);
      } else {
        setUserAddedOptions([]);
      }
    } catch (e) {
      console.error("Error", e);
    }
  };

  return (
    <Tab.Pane eventKey={eventKey}>
      <div className="regular-tab-pane">
        <div className="search-container-input">
          <BaseInput
            type="text"
            placeholder="Search..."
            icon={Search}
            value={searchValue}
            onChange={handleCustomValueChange}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between mb-2 tab-pane-selector">
          <span className="tab-pane-title">
            <BaseCheckbox
              type="checkbox"
              label={`Select All Filters (${displayData?.length})`}
              checked={
                displayData.length > 0 &&
                displayData.every((item) => checkedItems.get(item.name))
              }
              onChange={selectAllCheckbox}
              className="base-checkbox"
            />
          </span>
          <span
            className="cursor-pointer tab-pane-title title-clear"
            onClick={activeFilters.length > 0 ? clearAllCheckboxes : undefined}
            style={{
              textDecoration: "underline",
              opacity: activeFilters.length > 0 ? 1 : 0.5,
              pointerEvents: activeFilters.length > 0 ? "auto" : "none",
            }}
          >
            Clear All
          </span>
        </div>
        <div className="mt-2 ">
          {displayData.map((item) => (
            <div key={item.id} className="d-flex mb-3">
              <BaseCheckbox
                type="checkbox"
                name={`checkbox-${item.name}`}
                label={item.name}
                checked={checkedItems.get(item.name) || false}
                onChange={() => handleCheckboxChange(item)}
                className="base-checkbox"
              />
            </div>
          ))}
          {isBackendProcess &&
          userAddedOptions?.length == 0 &&
          searchValue?.length > 1 ? (
            <div className="no-record-title">No Value Found</div>
          ) : (
            ""
          )}
        </div>
      </div>
    </Tab.Pane>
  );
};

export default TabPaneContent;
