import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import BaseFilter from "../../../../components/filter/BaseFilter.js";
import WebScanner from "../../../../components/web-scanner/WebScanner.js";
import WebUpdate from "../../../../components/website-updates/CVEWebUpdate.js";
import DoughnutChart from "../../../../components/charts/DoughnutChart.js";
import axios from "../../../../util/axios.js";
import { useParams, useOutletContext, useLocation } from "react-router-dom";
import { ThreeCircles } from "react-loader-spinner";
import GenericCard from "../../../../components/card/GenericCard.js";
import PortsList from "./CvePortList.js";
import NoData from "../../../../components/empty/NoData.js";
import { NoDataAfterScanning } from "../../../../components/empty/NoDataAfterScanning.js";
import { ReactComponent as SortNotSelected } from "../../../../assets/images/SortNotSelected.svg";
import { ReactComponent as SortDown } from "../../../../assets/images/SortDown.svg";
import { ReactComponent as SortUp } from "../../../../assets/images/SortUp.svg";
import { ScannerContext } from "../../../../components/ScannerContext.js";
import { formatScannerName } from "../../../../helpers/formatScannerName.js";
import moment from "moment";
import BarChart from "../../../../components/charts/BarChart.js";
import BasePagination from "../../../../components/table/BasePagination.js";
import {
  webUpdatesFilter,
  webDataFillter,
} from "../../../../util/genericFunctions.js";
import { parseFilterString, applyCondition } from "../../../../util/conditions.js";
import { portsTabsConfig } from "../../../../util/tabsConfig.js";
import { barChartOptions } from "../../../../util/chartUtils.js";
import { cveTabsConfig } from "../../../../util/tabsConfig.js";
import {
  cveIndication,
  cisaKev,
  riskScores,
  currentStates
} from './FilterOptions.js';


const CVE = () => {
  const { dropdownItems } = useContext(ScannerContext);
  const { scanningStatus } = useOutletContext();
  const routeParams = useParams();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [isDatainProcess, setIsDataInProcess] = useState(false);
  const [isDataisPartiallyProcessing, setIsDataisPartiallyProcessing] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [noDataDoughnutChart, setNoDataDoughnutChart] = useState(false);
  const [noDataVerticalBarChart, setNoDataVerticalBarChart] = useState(false);
  const [ports, setPorts] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [isSortIconHovered, setIsSortIconHovered] = useState(false);
  const [sortDirection, setSortDirection] = useState("");
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [webUpdatesData, setWebUpdatesData] = useState(null);
  const [updatesRemove, setUpdatesRemove] = useState(null);
  const [tabs, setTabs] = useState([]);


  const [doughnutChartType, setDoughnutChartType] = useState({
    labels: ["Yes", "No"],
    datasets: [{
      data: [],
      backgroundColor: ["#FF6155", "#3DDC97"],
      hoverBackgroundColor: ["#FF6155", "#3DDC97"],
      borderColor: "transparent",
      borderWidth: 0,
    }],
  });


  const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || "";
            const value = context.raw;
            return `${label}: ${value}`;
          },
        },
        yAlign: "bottom",
        xAlign: "center",
      },

      title: {
        display: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
        borderColor: "transparent",
      },
    },
    onHover: (event, chartElement) => {
      const targetCanvas = event.native ? event.native.target : event.target;
      if (chartElement.length) {
        targetCanvas.style.cursor = "pointer";
      } else {
        targetCanvas.style.cursor = "default";
      }
    },
  };


  const sortPortsByIP = () => {
    let newSortDirection = "";
    if (sortDirection === "") {
      newSortDirection = "asc";
    } else if (sortDirection === "asc") {
      newSortDirection = "desc";
    } else if (sortDirection === "desc") {
      newSortDirection = "";
    }

    const sortedPorts = [...ports];
    if (newSortDirection !== "") {
      sortedPorts.sort((a, b) => {
        const ipA = a.ip.split(".").map((num) => parseInt(num, 10));
        const ipB = b.ip.split(".").map((num) => parseInt(num, 10));

        for (let i = 0; i < ipA.length; i++) {
          if (ipA[i] - ipB[i] !== 0) {
            return newSortDirection === "asc"
              ? ipA[i] - ipB[i]
              : ipB[i] - ipA[i];
          }
        }
        return 0;
      });
    }

    setPorts(sortedPorts);
    setSortDirection(newSortDirection);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
    setCurrentPage(1);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const updateTabsWithData = (portsData) => {
    // Port Number filters
    const portsName = Array.from(
      new Set(portsData.flatMap((port) => port.scan_ports.map((p) => p.port)))
    ).map((port) => ({
      id: port,
      name: port.toString(),
      type: "Port: " + port.toString(),
      key: "2",
      active: false,
    }));

    // First Detected filters
    const firstDetectedDates = Array.from(
      new Set(
        portsData.flatMap(port =>
          port.scan_ports.map(p => moment(p.first_detected).format('YYYY-MM-DD'))
        )
      )
    ).filter(date => date)
    .map(date => ({
      id: date,
      name: moment(date).format('MMM DD, YYYY'),
      type: `First Detected: ${moment(date).format('MMM DD, YYYY')}`,
      key: 'first-detected',
      active: false
    }));

    // Add Last Updated filter
    const lastUpdatedDates = Array.from(
      new Set(portsData.map(port => moment(port.updated_at).format('YYYY-MM-DD')))
    ).filter(date => date)
    .map(date => ({
      id: date,
      name: moment(date).format('MMM DD, YYYY'),
      type: `Last Updated: ${moment(date).format('MMM DD, YYYY')}`,
      key: 'last-updated',
      active: false
    }));

    // Get unique titles from scan ports
    const titles = Array.from(
      new Set(portsData.flatMap(port => 
        port.scan_ports.map(p => p.title)
      ))
    ).filter(title => title && title !== '-')
    .map(title => ({
      id: title,
      name: title,
      type: `Title: ${title}`,
      key: 'title',
      active: false
    }));

    // Get unique CVSS scores
    const cvssScores = Array.from(
      new Set(portsData.flatMap(port => 
        port.scan_ports.map(p => p.cvssScore)
      ))
    ).filter(score => score && score !== '-')
    .map(score => ({
      id: score,
      name: score.toString(),
      type: `CVSS Score: ${score}`,
      key: 'cvss-score',
      active: false
    }));

    // Get unique CVE numbers
    const cveNumbers = Array.from(
      new Set(portsData.flatMap(port => 
        port.scan_ports.flatMap(p => p.cveNumbers || [])
      ))
    ).filter(cve => cve && cve !== '-')
    .map(cve => ({
      id: cve,
      name: cve,
      type: `CVE Number: ${cve}`,
      key: 'cve-number',
      active: false
    }));

    setTabs(
      cveTabsConfig({
        portsName,
        cveIndication,
        titles,
        cvssScores,
        cisaKev,
        riskScores,
        cveNumbers,
        firstDetectedDates,
        lastUpdatedDates,
        currentStates
      })
    );
  };

  const fetchPorts = async () => {  
    try {
      const { data } = await axios.get(`scans/${routeParams?.target_id}/cve-scanner`);
      
      if (data?.changes) {
        setWebUpdatesData(data?.changes);
        setUpdatesRemove(data?.removed);
      }
      
      // Update doughnut chart for CVE Indication
      if(data?.ports?.cve_counts) {
        setDoughnutChartType({
          labels: ["Yes", "No"],
          datasets: [{
            data: [data.ports.cve_counts.yes, data.ports.cve_counts.no],
            backgroundColor: ["#FF6155", "#3DDC97"],
            hoverBackgroundColor: ["#FF6155", "#3DDC97"],
            borderColor: "transparent",
            borderWidth: 0,
          }],
        });
        // Set no data flag based on data presence
        setNoDataDoughnutChart(data.ports.cve_counts.yes === 0 && data.ports.cve_counts.no === 0);
      } else {
        setNoDataDoughnutChart(true);
      }

      // Update bar chart for Risk Scores
      if (data?.ports?.risk_counts) {
        const high = data.ports.risk_counts.high || 0;   // 80-100
        const medium = data.ports.risk_counts.medium || 0; // 40-79
        const low = data.ports.risk_counts.low || 0;     // 0-39
        
        setNoDataVerticalBarChart(high === 0 && medium === 0 && low === 0);
        
        setBarChartData({
          labels: ["High Risk", "Medium Risk", "Low Risk"],
          datasets: [{
            label: ["High Risk", "Medium Risk", "Low Risk"],
            data: [high, medium, low],
            backgroundColor: ["#FF6155", "#FF9F45", "#3DDC97"],
            borderColor: ["#FF6155", "#FF9F45", "#3DDC97"],
            borderWidth: 1,
            maxBarThickness: 60,
            borderRadius: {
              topLeft: 5,
              topRight: 5,
            },
          }],
        });
      } else {
        setNoDataVerticalBarChart(true);
      }

      // Process ports data from API
      if (data?.ports?.ports && data.ports.ports.length > 0) {
        const processedPorts = data.ports.ports.map(ipData => {
          // Format ports information with appropriate structure
          const scanPorts = ipData.ports ? ipData.ports.flatMap(port => {
            // If no issues, return single port entry
            if (!port.issues || port.issues.length === 0) {
              return [{
                id: port.id,
                port: port.port.toString(),
                service: port.service || "",
                product: port.product || "",
                version: port.version || "",
                is_encrypted: port.is_encrypted,
                change_status: port.change_status,
                updated_at: port.updated_at,
                changes: port.changes,
                first_detected: port.first_detected,
                hasCve: false,
                title: "-",
                cvssScore: "-",
                cisaKev: false,
                riskScore: "-",
                cveNumbers: ["-"]
              }];
            }

            // Map each issue to a separate port entry
            return port.issues.map(issue => ({
              id: port.id,
              port: port.port.toString(),
              service: port.service || "",
              product: port.product || "",
              version: port.version || "",
              is_encrypted: port.is_encrypted,
              change_status: port.change_status,
              updated_at: port.updated_at,
              changes: port.changes,
              first_detected: port.first_detected,
              hasCve: true,
              title: issue.issue?.issue_ai_result?.name,
              cvssScore: issue.issue.cvss,
              cisaKev: issue.issue.cisa_kev?.toLowerCase() === "yes",
              riskScore: issue.risk_score,
              cveNumbers: issue.issue.cve.split(" | ")
            }));
          }) : [];
          return {
            ip: ipData.ip,
            domain: ipData.domain || "",
            status: 0, // Default status
            scan_ports: scanPorts,
           // Use first detected as update time
          };
        });
        
        setPorts(processedPorts);
        updateTabsWithData(processedPorts);
      } else {
        setPorts([]);
      }
      
      setIsLoading(false);
    } catch (err) {
      console.error("Error fetching CVE scanner data:", err);
      setIsLoading(false);
    }
  };

  const getExportedRows = (dataForExport) => {
    const body = [];
    dataForExport.forEach((entry) => {
      entry.scan_ports.forEach((port) => {
        const row = [];
        row.push(
          entry.ip,
          port.port,
          port.hasCve ? "Yes" : "No",
          port.title,
          port.cvssScore,
          port.cisaKev ? "Yes" : "No",
          port.riskScore,
          Array.isArray(port.cveNumbers) && port.cveNumbers[0] !== "-" 
            ? port.cveNumbers.join("|") 
            : "-"
        );
        body.push(row);
      });
    });
    return body;
  };

  const handleWebUpdateSort = (name) => {
    setActiveFilters(webUpdatesFilter(name, activeFilters));
  };

  const statusMap = { new: 1, changed: 2, unchanged: 0 };

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const filteredPorts = ports
    .filter((port) => {
      const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
        return groupedFilters[eventKey].some((filter) => {
          const lowerCaseFilter = filter.name.toLowerCase();
          
          // Add this case for first-detected
          if (eventKey === "first-detected") {
            return port.scan_ports.some(scanPort => {
              const filterDate = moment(filter.name, 'MMM DD, YYYY').format('YYYY-MM-DD');
              const portDate = moment(scanPort.first_detected).format('YYYY-MM-DD');
              return filterDate === portDate;
            });
          }
          
          // Handle advanced filters
          if (eventKey === "advanced-filter") {
            const parsedFilters = parseFilterString(filter.name);
            return parsedFilters.every((rule) => {
              const { column, condition, value } = rule;
              
              switch (column) {
                case "port":
                  return port.scan_ports.some((scanPort) =>
                    applyCondition(scanPort.port.toString(), condition, value)
                  );
                case "title":
                  return port.scan_ports.some((scanPort) =>
                    applyCondition(scanPort.title, condition, value)
                  );
                case "ip":
                  return applyCondition(port.ip, condition, value);
                case "domain":
                  return applyCondition(port.domain || "", condition, value);
                case "has_cve":
                  return port.scan_ports.some((scanPort) => {
                    const cveStatus = scanPort.hasCve ? "yes" : "no";
                    return applyCondition(cveStatus, condition, value.toLowerCase());
                  });
                case "cvss_score":
                  return port.scan_ports.some((scanPort) =>
                    applyCondition(scanPort.cvssScore, condition, value)
                  );
                case "cisa_kev":
                  return port.scan_ports.some((scanPort) =>
                    applyCondition(scanPort.cisaKev ? "yes" : "no", condition, value.toLowerCase())
                  );
                case "risk_score":
                  return port.scan_ports.some((scanPort) => {
                    // Handle range format (e.g., "0-39")
                    if (value.includes("-")) {
                      const [min, max] = value.split("-").map(Number);
                      const score = Number(scanPort.riskScore);
                      return !isNaN(score) && score >= min && score <= max;
                    }
                    // Handle direct comparison
                    const score = Number(scanPort.riskScore);
                    return !isNaN(score) && applyCondition(score.toString(), condition, value);
                  });
                case "cve_number":
                  return port.scan_ports.some((scanPort) =>
                    scanPort.cveNumbers.some(cve => applyCondition(cve, condition, value))
                  );
                case "first_detected":
                  return port.scan_ports.some((scanPort) => {
                    const filterDate = moment(value, 'MMM DD, YYYY').format('YYYY-MM-DD');
                    const portDate = moment(scanPort.first_detected).format('YYYY-MM-DD');
                    return applyCondition(portDate, condition, filterDate);
                  });
                case "current_state":
                  return port.scan_ports.some((scanPort) =>
                    applyCondition(
                      scanPort.change_status,
                      condition,
                      statusMap[value.toLowerCase()]
                    )
                  );
                default:
                  return false;
              }
            });
          }
          
          switch (eventKey) {
            case "cve-indication":
              return port.scan_ports.some(scanPort => {
                return (lowerCaseFilter === "yes") === scanPort.hasCve;
              });

            case "title":
              return port.scan_ports.some(scanPort => {
                return scanPort.title === filter.name;
              });

            case "cvss-score":
              return port.scan_ports.some(scanPort => {
                return scanPort.cvssScore.toString() === filter.name;
              });

            case "cisa-kev":
              return port.scan_ports.some(scanPort => {
                return (lowerCaseFilter === "yes") === scanPort.cisaKev;
              });

            case "risk-score":
              return port.scan_ports.some(scanPort => {
                if (typeof scanPort.riskScore !== 'number') return false;
                
                // Parse the range from filter name (e.g., "0-39" for low)
                const [min, max] = filter.name.split("-").map(Number);
                return scanPort.riskScore >= min && scanPort.riskScore <= max;
              });

            case "cve-number":
              return port.scan_ports.some(scanPort => {
                return scanPort.cveNumbers && scanPort.cveNumbers.some(cve => cve === filter.name);
              });

            case "current-state":
              return port.scan_ports.some(scanPort => 
                scanPort.change_status === statusMap[lowerCaseFilter]
              );

            case "1":
              return port.ip && port.ip.toLowerCase() === lowerCaseFilter;
            case "7":
              return port.domain && port.domain.toLowerCase() === lowerCaseFilter;
            case "2":
              return port.scan_ports.some(
                (scanPort) => scanPort.port.toString() === lowerCaseFilter
              );
            case "3":
              return port.scan_ports.some(
                (scanPort) =>
                  scanPort.service &&
                  scanPort.service.toLowerCase() === lowerCaseFilter
              );
            case "4":
              return port.scan_ports.some(
                (scanPort) =>
                  `${scanPort.product || ""} ${scanPort.version || ""}`
                    .trim()
                    .toLowerCase() === lowerCaseFilter
              );
            case "5":
              return (
                (lowerCaseFilter === "yes" &&
                  port.scan_ports.some((scanPort) => scanPort.is_encrypted)) ||
                (lowerCaseFilter === "no" &&
                  port.scan_ports.some((scanPort) => !scanPort.is_encrypted))
              );
            case "6":
              return port.scan_ports.some(
                (scanPort) =>
                  scanPort.change_status === statusMap[filter.name.toLowerCase()]
              );
            default:
              return false;
          }
        });
      });

      const matchesSearch =
        searchValue === "" ||
        port.ip.toLowerCase().includes(searchValue.toLowerCase()) ||
        port.scan_ports.some(
          (scanPort) =>
            scanPort.port.toString().includes(searchValue.toLowerCase()) ||
            (scanPort.service &&
              scanPort.service
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            `${scanPort.product || ""} ${scanPort.version || ""}`
              .trim()
              .toLowerCase()
              .includes(searchValue.toLowerCase())
        );
      return matchesFilters && matchesSearch;
    })
    .map((port) => {
      return {
        ...port,
        scan_ports: port.scan_ports.filter((scanPort) => {
          // If there are no filters, keep all ports
          if (activeFilters.length === 0) return true;

          // Track if we've already matched any filter in a given group
          const filterGroupMatches = {};

          // If we have a risk score filter, apply it with OR logic
          if (groupedFilters["risk-score"]) {
            filterGroupMatches["risk-score"] = groupedFilters["risk-score"].some(riskScoreFilter => {
              const [min, max] = riskScoreFilter.name.split("-").map(Number);
              const score = Number(scanPort.riskScore);
              return !isNaN(score) && score >= min && score <= max;
            });
            
            // If no risk score matches, filter this port out
            if (!filterGroupMatches["risk-score"]) return false;
          }

          // Handle CVE indication filter with OR logic
          if (groupedFilters["cve-indication"]) {
            filterGroupMatches["cve-indication"] = groupedFilters["cve-indication"].some(filter => {
              const wantsCve = filter.name.toLowerCase() === "yes";
              return wantsCve === scanPort.hasCve;
            });
            
            if (!filterGroupMatches["cve-indication"]) return false;
          }

          // Handle title filter with OR logic
          if (groupedFilters["title"]) {
            filterGroupMatches["title"] = groupedFilters["title"].some(filter => 
              scanPort.title === filter.name
            );
            
            if (!filterGroupMatches["title"]) return false;
          }

          // Handle CVSS Score filter with OR logic
          if (groupedFilters["cvss-score"]) {
            filterGroupMatches["cvss-score"] = groupedFilters["cvss-score"].some(filter => 
              scanPort.cvssScore.toString() === filter.name
            );
            
            if (!filterGroupMatches["cvss-score"]) return false;
          }

          // Handle CISA KEV filter with OR logic
          if (groupedFilters["cisa-kev"]) {
            filterGroupMatches["cisa-kev"] = groupedFilters["cisa-kev"].some(filter => {
              const wantsCisaKev = filter.name.toLowerCase() === "yes";
              return wantsCisaKev === scanPort.cisaKev;
            });
            
            if (!filterGroupMatches["cisa-kev"]) return false;
          }

          // Handle CVE Number filter with OR logic
          if (groupedFilters["cve-number"]) {
            filterGroupMatches["cve-number"] = groupedFilters["cve-number"].some(filter =>
              scanPort.cveNumbers && scanPort.cveNumbers.some(cve => cve === filter.name)
            );
            
            if (!filterGroupMatches["cve-number"]) return false;
          }

          // Handle First Detected filter with OR logic
          if (groupedFilters["first-detected"]) {
            filterGroupMatches["first-detected"] = groupedFilters["first-detected"].some(filter => {
              const filterDate = moment(filter.name, 'MMM DD, YYYY').format('YYYY-MM-DD');
              const portDate = moment(scanPort.first_detected).format('YYYY-MM-DD');
              return filterDate === portDate;
            });
            
            if (!filterGroupMatches["first-detected"]) return false;
          }

          // Handle port filter with OR logic
          if (groupedFilters["2"]) {
            filterGroupMatches["2"] = groupedFilters["2"].some(filter => 
              scanPort.port.toString() === filter.name.toLowerCase()
            );
            
            if (!filterGroupMatches["2"]) return false;
          }

          // Handle current state filter with OR logic
          if (groupedFilters["current-state"]) {
            filterGroupMatches["current-state"] = groupedFilters["current-state"].some(filter => 
              scanPort.change_status === statusMap[filter.name.toLowerCase()]
            );
            
            if (!filterGroupMatches["current-state"]) return false;
          }

          // Handle advanced filters
          if (groupedFilters["advanced-filter"]) {
            // For advanced filters, we'll keep the original logic since they're more complex
            const advancedFilterResults = [];
            
            groupedFilters["advanced-filter"].forEach(filter => {
              const parsedFilters = parseFilterString(filter.name);
              const ruleResults = parsedFilters.map(rule => {
                const { column, condition, value } = rule;
                
                switch (column) {
                  case "port":
                    return applyCondition(scanPort.port.toString(), condition, value);
                  case "title":
                    return applyCondition(scanPort.title, condition, value);
                  case "cvss_score":
                    return applyCondition(scanPort.cvssScore.toString(), condition, value);
                  case "has_cve":
                    return applyCondition(
                      scanPort.hasCve ? "yes" : "no",
                      condition,
                      value.toLowerCase()
                    );
                  case "cisa_kev":
                    return applyCondition(scanPort.cisaKev ? "yes" : "no", condition, value.toLowerCase());
                  case "risk_score":
                    if (value.includes("-")) {
                      const [min, max] = value.split("-").map(Number);
                      const score = Number(scanPort.riskScore);
                      return !isNaN(score) && score >= min && score <= max;
                    } else {
                      const score = Number(scanPort.riskScore);
                      return !isNaN(score) && applyCondition(score.toString(), condition, value);
                    }
                  case "cve_number":
                    return scanPort.cveNumbers.some(cve => applyCondition(cve, condition, value));
                  case "first_detected":
                    const filterDate = moment(value, 'MMM DD, YYYY').format('YYYY-MM-DD');
                    const portDate = moment(scanPort.first_detected).format('YYYY-MM-DD');
                    return applyCondition(portDate, condition, filterDate);
                  case "current_state":
                    return applyCondition(
                      scanPort.change_status.toString(),
                      condition,
                      statusMap[value.toLowerCase()].toString()
                    );
                  default:
                    return false;
                }
              });
              
              // All rules within a single advanced filter must match (AND logic)
              advancedFilterResults.push(ruleResults.every(result => result));
            });
            
            // At least one advanced filter must match completely (OR logic between different advanced filters)
            filterGroupMatches["advanced-filter"] = advancedFilterResults.some(result => result);
            
            if (!filterGroupMatches["advanced-filter"]) return false;
          }

          // If we've made it this far, the port matches all required filter groups
          return true;
        })
      };
    })
    // Remove any ports that have no matching scan_ports after filtering
    .filter(port => port.scan_ports.length > 0);

    const [barChartData, setBarChartData] = useState({
      labels: ["High Risk", "Medium Risk", "Low Risk"],
      datasets: [{
        label: ["High Risk", "Medium Risk", "Low Risk"],
        data: [0, 0, 0],
        backgroundColor: ["#FF6155", "#FF9F45", "#3DDC97"],
        borderColor: ["#FF6155", "#FF9F45", "#3DDC97"],
        borderWidth: 1,
        maxBarThickness: 60,
        borderRadius: {
          topLeft: 5,
          topRight: 5,
        },
      }],
    });
  
  const handleChartClick = (name) => {
    if (name === "Yes" || name === "No") {
      const updatedFilters = webUpdatesFilter(
        name,
        activeFilters,
        "cve-indication",
        "CVE Indication"
      );
      
      setActiveFilters(updatedFilters);
      setCurrentPage(1);
      return;
    }
    
    if (name === "High Risk" || name === "Medium Risk" || name === "Low Risk") {
      let riskRange;
      if (name === "High Risk") {
        riskRange = "80-100";
      } else if (name === "Medium Risk") {
        riskRange = "40-79";
      } else if (name === "Low Risk") {
        riskRange = "0-39";
      }
      
      const updatedFilters = webUpdatesFilter(
        riskRange,
        activeFilters,
        "risk-score",
        "Risk Score"
      );
      
      setActiveFilters(updatedFilters);
      setCurrentPage(1);
      return;
    }
    
    const [portText, portNumber] = name.split(" ");

    const formattedPortText = /^[a-zA-Z]+$/.test(portText)
      ? portText.toLowerCase()
      : portText;
    const updatedFiltersForPort = webUpdatesFilter(
      portNumber.toString(),
      activeFilters,
      "2",
      "Port"
    );

    const updatedFiltersForService = webUpdatesFilter(
      formattedPortText,
      updatedFiltersForPort,
      "3",
      "Service"
    );
    const updatedPortTabData = tabs[1].data.map((port) => ({
      ...port,
      active: updatedFiltersForPort.some((filter) => filter.name === port.name),
    }));

    const updatedServiceTabData = tabs[2].data.map((service) => ({
      ...service,
      active: updatedFiltersForService.some(
        (filter) => filter.name === service.name
      ),
    }));

    setTabs((prevTabs) => {
      const newTabs = [...prevTabs];
      newTabs[1] = { ...newTabs[1], data: updatedPortTabData };
      newTabs[2] = { ...newTabs[2], data: updatedServiceTabData };
      return newTabs;
    });

    setActiveFilters(updatedFiltersForService);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredPorts.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const totalPages = Math.ceil(filteredPorts.length / recordsPerPage);
  const fetchIntervalRef = useRef();
  useEffect(() => {
    clearInterval(fetchIntervalRef.current);

    if (scanningStatus === 0) {
      setIsLoading(false);
      setIsDataInProcess(true);
    } else {
      setIsDataInProcess(false);
      if (
        scanningStatus === 3 ||
        scanningStatus === -1 ||
        scanningStatus === 4
      ) {
        setIsDataisPartiallyProcessing(false);
        setIsLoading(true);
        fetchPorts();
      } else if (scanningStatus === 2 || scanningStatus === 1) {
        setIsDataisPartiallyProcessing(true);
        setIsLoading(true);
        fetchPorts();
        fetchIntervalRef.current = setInterval(fetchPorts, 5000);
      }
    }
    return () => {
      clearInterval(fetchIntervalRef.current);
    };
  }, [scanningStatus, routeParams?.target_id]);

  const handleBarChartClick = (label) => {
    let riskRange;
    switch (label) {
      case "High":
        riskRange = "80-100";
        break;
      case "Medium":
        riskRange = "40-79";
        break;
      case "Low":
        riskRange = "0-39";
        break;
      default:
        return;
    }
    
    const updatedFilters = webUpdatesFilter(
      riskRange,
      activeFilters,
      "risk-score",
      "Risk Score"
    );

    // Update the active state of risk score filters in tabs
    const updatedRiskScoreTabs = tabs.map(tab => {
      if (tab.eventKey === "risk-score") {
        return {
          ...tab,
          data: tab.data.map(item => ({
            ...item,
            active: item.name === riskRange
          }))
        };
      }
      return tab;
    });
    
    setTabs(updatedRiskScoreTabs);
    setActiveFilters(updatedFilters);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (location?.search) {
      const searchParams = new URLSearchParams(location.search);
      const ip = searchParams.get("ip");
      const isNew = searchParams.get("is_new");

      const newFilters = [];

      if (ip) {
        newFilters.push({
          eventKey: "1",
          name: ip,
          type: `IP Address: ${ip}`,
        });
      }

      if (isNew) {
        newFilters.push({
          eventKey: "6",
          name: "New",
          type: `Current State: New`,
        });
      }

      setActiveFilters(newFilters);
    }
  }, [location?.search]);

  const getRiskScoreColor = (score) => {
    if (typeof score !== 'number') return '';
    if (score >= 80) return '#FF6155'; // Red for High Risk
    if (score >= 40) return '#FF9F45'; // Orange for Medium Risk
    return '#3DDC97'; // Green for Low Risk
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="main_container_style">
          <div className="pb-4 row top-container-main web-updates-container">
            <div className="col-12 col-lg-6 h-100 web-updates-col-7">
              <div className="row gap-4 top-container-main-row">
                <div className="col-12 h-50">
                  <GenericCard
                    children={
                      isDatainProcess ? (
                        <NoData />
                      ) : isDataisPartiallyProcessing && noDataDoughnutChart ? (
                        <NoData />
                      ) : noDataDoughnutChart ? (
                        <NoDataAfterScanning />
                      ) : (
                        <DoughnutChart
                          data={doughnutChartType}
                          options={doughnutChartOptions}
                          onHandleClick={handleChartClick}
                        />
                      )
                    }
                    title={"CVE Indication"}
                  />
                </div>
                <div className="col-12 h-50">
                  <div className="row h-100">
                    <div className="col-5">
                      <GenericCard
                        children={
                          <WebUpdate
                            data={webUpdatesData}
                            onSort={handleWebUpdateSort}
                            dataInProcess={
                              isDatainProcess || isDataisPartiallyProcessing
                            }
                          />
                        }
                        title={"Updates"}
                      />
                    </div>
                    <div className="col-7">
                      <GenericCard
                        children={
                          isDatainProcess ? (
                            <NoData />
                          ) : isDataisPartiallyProcessing &&
                            noDataDoughnutChart ? (
                            <NoData />
                          ) : noDataVerticalBarChart ? (
                            <NoDataAfterScanning />
                          ) : (
                            <>
                              <BarChart
                                barData={barChartData}
                                optionsData={barChartOptions}
                                onBarClick={handleBarChartClick}
                                isData={!!(
                                  barChartData &&
                                  barChartData.datasets &&
                                  barChartData.datasets[0].data.some(value => value > 0)
                                )}
                                dynamicLabels={["High", "Medium", "Low"]}
                                isChartLabelsOnTop
                              />
                            </>
                          )
                        }
                        title={"Risk Score"}
                        subtitle={"View Issues"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 h-100 web-updates-col-5">
              <WebScanner title={"Ports, Services & Protocols Scanner"} />
            </div>
          </div>

          <div className=" pb-5">
            <div className="py-4">
              <BaseFilter
                tabs={tabs}
                onFilterChange={handleFilterChange}
                activeFilters={activeFilters}
                removeFilter={removeFilter}
                className="mb-3"
                totalRecords={filteredPorts.length}
                exportHeader={[
                  "IP Address",
                  "Port",
                  "CVE Indication",
                  "Title",
                  "CVSS Score",
                  "CISA KEV",
                  "Risk Score",
                  "CVE Numbers"
                ]}
                exportTitle={`CVEScanner_${formatScannerName(
                  dropdownItems.filter((item) => {
                    return item.target_uuid === routeParams?.target_id;
                  })[0]?.title
                )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
                exportRows={getExportedRows(filteredPorts)}
                isDatainProcess={isDatainProcess || isDataisPartiallyProcessing}
                searchValue={searchValue}
                onSearchChange={handleSearchChange}
                isSearch={true}
                tableData={filteredPorts}
              />
            </div>
            <div>
              <div
                className="port-sort-container d-flex align-items-center"
                onMouseEnter={() => setIsSortIconHovered(true)}
                onMouseLeave={() => setIsSortIconHovered(false)}
                onClick={sortPortsByIP}
              >
                IP Address
                {isSortIconHovered || sortDirection ? (
                  sortDirection === "desc" ? (
                    <SortDown />
                  ) : sortDirection === "asc" ? (
                    <SortUp />
                  ) : (
                    <SortNotSelected />
                  )
                ) : null}
              </div>
              {isDatainProcess ? (
                <div className="data-in-process">Data in Process </div>
              ) : (
                <div>
                  {currentRecords.length > 0 ? (
                    currentRecords.map((ipData, index) => (
                      <PortsList
                        key={`${ipData.ip}-${ipData.ip_id || ''}-${index}`}
                        port={ipData}
                        ipStatus={ipData.status}
                      />
                    ))
                  ) : (
                    <div className="data-in-process">
                      {isDataisPartiallyProcessing
                        ? "Data in Process"
                        : "No Data Was Found"}
                    </div>
                  )}
                  <BasePagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    recordsPerPage={recordsPerPage}
                    dataLength={filteredPorts.length}
                    handlePageChange={handlePageChange}
                    handelPerPage={(value) => {
                      setCurrentPage(1);
                      setRecordsPerPage(value);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CVE;
