export const generateRemovedMessages = (updatesRemove, pageName) => {
  const messageConfig = {
    removeCert: (host) => ` Certificate For Hostname ${host} Has Been Removed`,
    removedPorts: (item) => {
      const [ip, port] = item.split(":");
      return pageName === "Certificates"
        ? `Certificate For IP ${ip} And Port ${port} Has Been Removed`
        : `Port ${port} On IP ${ip} Has Been Removed`;
    },
    removedHost: (host) => `Hostname ${host} Has Been Removed`,
    inactiveHosts: (host) => `Hostname ${host} Has Been Inactive`,
    removedIps: (ip) => `IP ${ip} Has Been Removed`,
    inactiveIP: (ip) => `IP ${ip} Has Been Inactive`,
    removedUrl: (item) => {
      if (item?.category) {
        const hostname = new URL(item.url).hostname;
        return `URL On Hostname ${hostname} Under ${item.category} Category Has Been Removed`;
      } else {
        const host = item.url.split("/login")[0];
        return `Login Page On ${host} Has Been Removed`;
      }
    },
  };

  const messages = Object.entries(messageConfig).flatMap(([key, messageFn]) => {
    const updates = updatesRemove?.[key];
    return Array.isArray(updates) ? updates.map(messageFn) : [];
  });

  return messages;
};
