import React, { useState, useEffect, useMemo } from "react";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import TabPaneContent from "./CustomTabPane";
import { ReactComponent as ChevronDown } from "../../assets/images/chevrondown.svg";
import { ReactComponent as ChevronUp } from "../../assets/images/chevronup.svg";
import { ReactComponent as Cross } from "../../assets/images/cross-icon.svg";
import { ReactComponent as Circle } from "../../assets/images/plus-circle.svg";
import FilterSelect from "./FilterSelect";
import { getConditionsForColumn } from "../../util/conditions";

const CustomTabContainer = ({
  tabs,
  activeKey,
  onSelect,
  onDomainChange,
  activeFilters,
  isBackendProcess = false
}) => {
  const [rules, setRules] = useState([
    { column: "", condition: "", value: "" },
  ]);
  const [showRule, setShowRule] = useState(true);
  const columns = useMemo(() => {
    return tabs.map((tab) => ({
      label: `${tab.title} (${tab.data?.length})`,
      value: tab.data_key,
    }));
  }, [tabs]);

  const addRule = () => {
    setRules([...rules, { column: "", condition: "", value: "" }]);
  };

  const addAdvancedFilterChip = (newRules) => {
    const allRulesFilled = newRules.every(
      (rule) => rule.column && rule.condition && rule.value
    );

    if (allRulesFilled) {
      const names = [];
      const types = [];
      const ids = [];

      newRules.forEach((rule) => {
        const tabData = tabs?.find((el) => el.data_key === rule.column);
        const selectedItem = tabData?.data?.find(
          (item) => item.name === rule.value
        );

        const typeName = tabData?.title;
        const idValue = selectedItem ? selectedItem.id : rule.value;

        names.push(`${rule.column},${rule.condition},${rule.value}`);
        types.push(`${typeName},${rule.condition},${rule.value}`);
        if (idValue !== null) {
          ids.push(idValue);
        }
      });

      const name = names.join("|");
      const type = types.join("|");
      const id = ids?.join("|");

      const items = {
        name,
        key: "advanced-filter",
        type,
        id,
      };

      onDomainChange(items, true);
    }
  };

  const deleteRule = (rowIndex) => {
    const updatedRule = rules?.filter((el, index) => index != rowIndex);
    setRules(updatedRule);
    addAdvancedFilterChip(updatedRule);
  };

  const handleRuleChange = (index, key, value) => {
    const newRules = [...rules];
    newRules[index][key] = value;
    if (key == "column") {
      newRules[index]["condition"] = "";
      newRules[index]["value"] = "";
    }
    if (key == "condition") {
      if (key == "condition" && value == "is_empty") {
        newRules[index]["value"] = "Empty";
      } else if (key == "condition" && value == "is_not_empty") {
        newRules[index]["value"] = "Not Empty";
      } else {
        newRules[index]["value"] = "";
      }
    }
    setRules(newRules);

    addAdvancedFilterChip(newRules);
  };

  const handleShowRule = (value) => {
    setShowRule(!showRule);
  };

  useEffect(() => {
    const advanceFilterItem = activeFilters?.filter(
      (ol) => ol.eventKey === "advanced-filter"
    );
    if (advanceFilterItem?.length > 0) {
      const name = advanceFilterItem?.[0]?.name;
      let rules_ = [];
      name.split("|")?.map((el) => {
        let item = el.split(",");
        rules_.push({
          column: item[0],
          condition: item[1],
          value: item[2],
        });
      });
      setRules(rules_);
    }
  }, [activeFilters]);

  const renderAdvancedFilter = () => {
    const rulesHeight =
      rules.length == 2
        ? rules.length * 5
        : rules.length * 5 * (rules.length * 2);
    const centerY = (rulesHeight + 77) / 2 - 14;
    return (
      <div className="advance-filter-content">
        <div className="new-rule-text">
          {showRule ? (
            <ChevronDown className="cursor-pointer" onClick={handleShowRule} />
          ) : (
            <ChevronUp className="cursor-pointer" onClick={handleShowRule} />
          )}{" "}
          New Rule
        </div>
        {showRule && (
          <div>
            <div className="condition-container">
              <div
                className="and-operator-container"
                style={{
                  visibility: rules?.length > 1 ? "visible" : "hidden",
                }}
              >
                <span
                  style={{
                    top: `calc(40% + ${(rules.length - 2) * 8}px)`,
                  }}
                >
                  And
                </span>
                <svg
                  width="49"
                  height={rulesHeight + 77}
                  viewBox={`0 0 49 ${rulesHeight + 77}`}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d={`M49 1H22C20.8954 1 20 1.89543 20 3V${
                      rulesHeight + 72
                    }C20 73.1046 19.8954 74 20 ${rulesHeight + 72}H49`}
                    stroke="#64FFFF"
                    strokeWidth="2"
                  />
                  <path
                    d={`M6 ${centerY}C3.23858 ${centerY} 1 ${
                      centerY + 2.2386
                    } 1 ${centerY + 5}V${centerY + 23}C1 ${
                      centerY + 25.7614
                    } 3.23858 ${centerY + 28} 6 ${centerY + 28}H31C33.7614 ${
                      centerY + 28
                    } 36 ${centerY + 25.7614} 36 ${centerY + 23}V${
                      centerY + 5
                    }C36 ${
                      centerY + 2.2386
                    } 33.7614 ${centerY} 31 ${centerY}H6Z`}
                    fill="#3B3B3E"
                  />
                  <path
                    d={`M6 ${centerY}C3.23858 ${centerY} 1 ${
                      centerY + 2.2386
                    } 1 ${centerY + 5}V${centerY + 23}C1 ${
                      centerY + 25.7614
                    } 3.23858 ${centerY + 28} 6 ${centerY + 28}H31C33.7614 ${
                      centerY + 28
                    } 36 ${centerY + 25.7614} 36 ${centerY + 23}V${
                      centerY + 5
                    }C36 ${
                      centerY + 2.2386
                    } 33.7614 ${centerY} 31 ${centerY}H6Z`}
                    stroke="#64FFFF"
                    strokeWidth="2"
                  />
                </svg>
              </div>
              <div style={{ width: "100%" }}>
                {rules.map((rule, index) => {
                  let valueOptions =
                    tabs
                      .filter((el) => el.data_key == rule.column)?.[0]
                      ?.data?.map((ol) => ({
                        label: ol.name,
                        value: ol.name,
                      })) || [];
                  return (
                    <div key={index} className="rule-row mb-3">
                      <div className="condition-select">
                        <span>Column</span>
                        <FilterSelect
                          value={rule.column}
                          options={columns}
                          onChange={(value) =>
                            handleRuleChange(index, "column", value)
                          }
                        />
                      </div>
                      <div className="condition-select">
                        <span>Condition</span>
                        <FilterSelect
                          value={rule.condition}
                          options={
                            rule.column
                              ? getConditionsForColumn(rule.column)
                              : []
                          }
                          disabled={!rule.column}
                          onChange={(value) =>
                            handleRuleChange(index, "condition", value)
                          }
                        />
                      </div>
                      <div className="condition-select">
                        <span>Value</span>
                        <FilterSelect
                          value={rule.value}
                          options={valueOptions}
                          disabled={
                            !(rule.column && rule.condition) ||
                            rule.condition === "is_empty" ||
                            rule.condition === "is_not_empty"
                          }
                          onChange={(value) =>
                            handleRuleChange(index, "value", value)
                          }
                          hasCustomInput
                          isBackendProcess={isBackendProcess}
                          columnName={rule.column}
                          isDatePicker={
                            rule.condition == "last_24_hours" ||
                            rule.condition == "last_7_days" ||
                            rule.condition == "last_30_days" ||
                            rule.condition == "from" ||
                            rule.condition == "until" ||
                            rule.condition == "between"
                              ? rule.condition
                              : false
                          }
                          isEmpty={
                            rule.condition == "is_empty" ||
                            rule.condition == "is_not_empty"
                              ? rule.condition
                              : false
                          }
                        />
                      </div>
                      <div className="condition-select-action">
                        {index === rules.length - 1 && rules?.length <= 2 && (
                          <Circle onClick={addRule} />
                        )}
                        {rules?.length > 1 && (
                          <Cross
                            onClick={() => deleteRule(index)}
                            className="delete-icon"
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* <div className="filter-save-container">
              <span>Save</span>
            </div> */}
          </div>
        )}
        {/* <div className="select-advance-filter">
          <div className="select-all-container">
            <div className="checkbox-container">
              <BaseCheckbox
                type="checkbox"
              />
              Select All Advanced Filter (3)
            </div>
            <span className="clear-text">Clear All</span>
          </div>
        </div>
        <div className="select-advance-filter-options">
          {[1, 2, 3].map(() => (
            <div className="select-all-container">
              <div className="checkbox-container">
                <BaseCheckbox type="checkbox" />
                <div className="condition-contains">
                  <div className="multi-condition-box">
                    {[1, 2, 3].map((el) => {
                      return (
                        <div className="condition-box">
                          &#91; &lt;Domain&gt; &lt;Starts With&gt; &lt;Ciso&gt;
                          {el != 3 && <Circle />}
                        </div>
                      );
                    })}
                  </div>
                  <Cross className="cross-icon" />
                </div>
              </div>
            </div>
          ))}
        </div> */}
      </div>
    );
  };

  return (
    <>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={activeKey}
        onSelect={onSelect}
      >
        <Row className="m-0 advance-filter-row">
          <Col sm={3} className="p-0 mb-4 advance-col">
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link
                  className="rounded-0 advanced-navlinks"
                  eventKey="advanced-filter"
                >
                  {activeFilters?.some(
                    (ol) => ol.eventKey === "advanced-filter"
                  ) && <div className="active-circle-filter" />}
                  Advanced Filter
                </Nav.Link>
              </Nav.Item>
              {tabs.map((tab) => {
                const hasActiveFilter = activeFilters?.some(
                  (ol) => ol.eventKey === tab.eventKey
                );
                return (
                  <Nav.Item key={tab.eventKey}>
                    <Nav.Link
                      className="rounded-0 advanced-navlinks"
                      eventKey={tab.eventKey}
                    >
                      {hasActiveFilter && (
                        <div className="active-circle-filter" />
                      )}
                      {tab.title} ({tab?.data?.length})
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
          </Col>
          <Col sm={9} className="p-0 pb-4 tab-content-container advance-col">
            <Tab.Content className=" pt-3 ps-2 pe-2">
              <Tab.Pane eventKey="advanced-filter">
                {renderAdvancedFilter()}
              </Tab.Pane>
              {tabs.map((tab) => (
                <TabPaneContent
                  key={tab.eventKey}
                  data={tab.data}
                  eventKey={tab.eventKey}
                  title={tab.contentTitle}
                  onDomainChange={onDomainChange}
                  activeFilters={activeFilters}
                  isBackendProcess={isBackendProcess}
                />
              ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default CustomTabContainer;
