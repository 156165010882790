import React, { useState, useEffect } from "react";
import { ReactComponent as ChevronDown } from "../../../../assets/images/chevron-down-white.svg";
import { ReactComponent as ChevronRight } from "../../../../assets/images/chevron-right-white.svg";
import DecisionToggle from "../../../../components/badge/DecisionToggle.js";
import { ThreeCircles } from "react-loader-spinner";
import moment from "moment";
import CurrentStatus from "../../../../components/status/CurrentStatus.js";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../../../../util/formatDate.js";
import { UseAssetsModal } from "../../../../hooks/useAssetsModal.js";
import { OverlayTrigger, Popover, Overlay } from "react-bootstrap";
import { ReactComponent as ExternalLinkIcon } from "../../../../assets/images/external-link-icon.svg";

const PortsList = ({ port, ipStatus, cveStaticData }) => {
  const { openModal } = UseAssetsModal();
  const [isCollapsed, setIsCollapsed] = useState(ipStatus == -1 ? true : false);
  const navigate = useNavigate();
  const routeParams = useParams();
  const [cveData, setCveData] = useState({});
  const [isLoadingCveData, setIsLoadingCveData] = useState(false);
  const [hoveredCve, setHoveredCve] = useState(null);
  const [showPopover, setShowPopover] = useState(false);
  const portRefs = React.useRef({});

  const toggleCollapse = () => {
    if (ipStatus == -1 || port?.scan_ports.length == 0) {
      return;
    }
    setIsCollapsed((prevState) => !prevState);
  };
  
  const SecurityIssue = ({ ip }, { is_encrypted, port }) => {
    if (!is_encrypted) {
      navigate(
        `/security-issues/${routeParams?.target_id}?ip=${ip}&port=${port}`
      );
    }
  };

  // Add this navigation helper function near the top of the component
  const navigateToSecurityIssues = (ip, port, filterType, issueName) => {
    const queryParams = new URLSearchParams({
      ip,
      port,
      ...(issueName && { issue_name: issueName })
    }).toString();

    navigate(`/security-issues/${routeParams?.target_id}?${queryParams}`);
  };

  // Prepare CVE data when port is expanded
  useEffect(() => {
    if (!isCollapsed && port?.scan_ports?.length > 0) {
      setIsLoadingCveData(true);
      try {
        // Use the data directly from scan_ports which now contains the CVE information
        const data = {};
        port.scan_ports.forEach(scan => {
          data[scan.port] = {
            hasCve: scan.hasCve || false,
            title: scan.title || "-",
            cvssScore: scan.cvssScore || "-",
            cisaKev: scan.cisaKev || false,
            riskScore: scan.riskScore || "-",
            cveNumbers: scan.cveNumbers || ["-"],
          };
        });
        setCveData(data);
      } catch (error) {
        console.error("Error preparing CVE data:", error);
      } finally {
        setIsLoadingCveData(false);
      }
    }
  }, [isCollapsed, port]);

  // Function to get risk score color
  const getRiskScoreColor = (score) => {
    if (typeof score !== 'number') return '';
    if (score >= 80) return '#FF6155'; // Red
    if (score >= 40) return '#FF9F45'; // Orange
    return '#3DDC97'; // Green
  };

  // Function to get summary text for collapsed view
  const getCollapsedSummary = () => {
    if (!port?.scan_ports || port.scan_ports.length === 0) {
      return "No open ports were found";
    }

    const portsWithCVEs = port.scan_ports.filter(scan => scan.hasCve).length;
    const portText = port.scan_ports.length === 1 ? "Port" : "Ports";
    
    if (portsWithCVEs === 0) {
      return `${port.scan_ports.length} ${portText} and no CVEs were found`;
    } else {
      const cveText = portsWithCVEs === 1 ? "CVE was" : "CVEs were";
      return `${port.scan_ports.length} ${portText} and ${portsWithCVEs} ${cveText} found`;
    }
  };

  useEffect(() => {
    if (port?.scan_ports?.length == 0) {
      setIsCollapsed(true);
    }
  }, [port]);

  // Add this function near the top of the component to calculate column width
  const getCveColumnWidth = (cveNumbers) => {
    if (!Array.isArray(cveNumbers) || cveNumbers[0] === "-") {
      return "150px"; // No CVEs
    }
      return "650px"; // Three or more CVEs
    
  };

  // Add this function to sort ports by risk score while maintaining scan_ports filtering
  const sortByRiskScore = (scanPorts) => {
    // Create a deep copy of the array to avoid modifying the original
    return [...scanPorts].sort((a, b) => {
      // First sort by risk score (high to low)
      const scoreA = typeof a.riskScore === 'number' ? a.riskScore : -1;
      const scoreB = typeof b.riskScore === 'number' ? b.riskScore : -1;
      
      // If risk scores are different, sort by them
      if (scoreB !== scoreA) {
        return scoreB - scoreA;
      }
      
      // If risk scores are the same, sort by port number (low to high)
      const portA = parseInt(a.port) || 0;
      const portB = parseInt(b.port) || 0;
      return portA - portB;
    });
  };

  return (
    <div className="cve-list-table">
      <div
        className="left-cve-container"
        onClick={toggleCollapse}
        style={{ width: isCollapsed ? "auto" : "170px" }}
      >
        {ipStatus == -1 ? (
          ""
        ) : (
          <>
            {isCollapsed ? (
              <ChevronRight className="cursor-pointer"/>
            ) : (
              <ChevronDown className="cursor-pointer"/>
            )}
          </>
        )}
        <span
          className="asset-link ms-2"
          style={{ 
            textDecoration: 'underline', 
            cursor: 'default',
            color: '#fff'
          }}
        >
          {port?.ip}
        </span>
      </div>
      <div
        className="right-ports-container"
        style={{ width: isCollapsed ? "auto" : "calc(100% - 170px)" }}
      >
        {isCollapsed ? (
          <div className="total-ports-details">
            <div className="divider ms-2" />
            <div className="detail-text">
              {ipStatus == -1 ? (
                "Port details are masked to prevent identification of network services"
              ) : (
                <>{getCollapsedSummary()}</>
              )}
            </div>
          </div>
        ) : (
          <div className="port-collapse-table-container">
            {ipStatus == -1 ? (
              ""
            ) : (
              <div className="port-collapse-table ms-0">
                <table className="sticky-table">
                  <thead>
                    <tr>
                      <th className={`sticky-column sticky-column-port sticky-header column-width-port`}>
                        {port?.is_processed || port?.scan_ports?.length > 0 ? (
                          "Port"
                        ) : (
                          <div className="d-flex align-items-center">
                            <ThreeCircles height={18} width={18} color="#fff" />
                            <span style={{ marginLeft: "8px" }}>Scanning...</span>
                          </div>
                        )}
                      </th>
                      <th className={`sticky-column sticky-column-cve-indication sticky-header column-width-cve-indication`}>
                        {port?.is_processed || port?.scan_ports?.length > 0 ? (
                          "CVE Indication"
                        ) : (
                          <div className="d-flex align-items-center"></div>
                        )}
                      </th>
                      <th className="column-width-title">
                        {port?.is_processed || port?.scan_ports?.length > 0 ? (
                          "Title"
                        ) : (
                          <div className="d-flex align-items-center"></div>
                        )}
                      </th>
                      <th className="column-width-cvss">
                        {port?.is_processed || port?.scan_ports?.length > 0 ? (
                          "CVSS Score"
                        ) : (
                          <div className="d-flex align-items-center"></div>
                        )}
                      </th>
                      <th className="column-width-cisa">
                        {port?.is_processed || port?.scan_ports?.length > 0 ? (
                          "CISA KEV"
                        ) : (
                          <div className="d-flex align-items-center"></div>
                        )}
                      </th>
                      <th className="column-width-risk">
                        {port?.is_processed || port?.scan_ports?.length > 0 ? (
                          "Risk Score"
                        ) : (
                          <div className="d-flex align-items-center"></div>
                        )}
                      </th>
                      <th 
                        className="column-width-cve-numbers"
                        style={{ 
                          minWidth: getCveColumnWidth(port?.scan_ports?.[0]?.cveNumbers || ["-"]),
                          maxWidth: getCveColumnWidth(port?.scan_ports?.[0]?.cveNumbers || ["-"]),
                          width: getCveColumnWidth(port?.scan_ports?.[0]?.cveNumbers || ["-"])
                        }}
                      >
                        {port?.is_processed || port?.scan_ports?.length > 0 ? (
                          "CVE Numbers"
                        ) : (
                          <div className="d-flex align-items-center"></div>
                        )}
                      </th>
                      <th className="column-width-first-detected">
                        {port?.is_processed || port?.scan_ports?.length > 0 ? (
                          "First Detected"
                        ) : (
                          <div className="d-flex align-items-center"></div>
                        )}
                      </th>
                      <th className="column-width-last-updated">
                        {port?.is_processed || port?.scan_ports?.length > 0 ? (
                          "Last Updated"
                        ) : (
                          <div className="d-flex align-items-center"></div>
                        )}
                      </th>
                      <th className="column-width-current-state">
                        {port?.is_processed || port?.scan_ports?.length > 0 ? (
                          "Current State"
                        ) : (
                          <div className="d-flex align-items-center"></div>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoadingCveData ? (
                      <tr>
                        <td colSpan="10" className="text-center py-3">
                          <ThreeCircles height={30} width={30} color="#fff" />
                        </td>
                      </tr>
                    ) : (
                      sortByRiskScore(port?.scan_ports || [])
                        ?.map((scan, index) => {
                          return (
                            <tr
                              key={`${port.ip}-${scan.port}-${scan.id || ''}-${index}`}
                              className={
                                port?.scan_ports.length === index + 1
                                  ? "border-0"
                                  : "borts-table-border"
                              }
                            >
                              <td className={`sticky-column sticky-column-port`}>
                                {scan.port || "-"}
                              </td>
                              <td className={`sticky-column sticky-column-cve-indication`}>
                                <div 
                                  onClick={() => scan.hasCve && navigateToSecurityIssues(port?.ip, scan.port, 'cve', scan.title)}
                                  style={{ cursor: scan.hasCve ? 'pointer' : 'default' }}
                                >
                                  <DecisionToggle
                                    label={scan.hasCve ? "Yes" : "No"}
                                    isIndication={!scan.hasCve}
                                  />
                                </div>
                              </td>
                              <td className="column-title-cell">
                                {scan.title}
                              </td>
                              <td>{scan.cvssScore}</td>
                              <td>
                                <div 
                                  onClick={() => scan.cisaKev && navigateToSecurityIssues(port?.ip, scan.port, 'cisaKev', scan.title)}
                                  style={{ cursor: scan.cisaKev ? 'pointer' : 'default' }}
                                >
                                  <DecisionToggle
                                    label={scan.cisaKev ? "Yes" : "No"}
                                    isIndication={!scan.cisaKev}
                                  />
                                </div>
                              </td>
                              <td>
                                <div 
                                  onClick={() => typeof scan.riskScore === 'number' && navigateToSecurityIssues(port?.ip, scan.port, 'riskScore', scan.title)}
                                  style={{ cursor: typeof scan.riskScore === 'number' ? 'pointer' : 'default' }}
                                >
                                  <span style={{ 
                                    color: getRiskScoreColor(scan.riskScore),
                                    fontWeight: 'bold'
                                  }}>
                                    {scan.riskScore}
                                  </span>
                                </div>
                              </td>
                              <td style={{ 
                                minWidth: getCveColumnWidth(scan.cveNumbers),
                                maxWidth: getCveColumnWidth(scan.cveNumbers),
                                width: getCveColumnWidth(scan.cveNumbers)
                              }}>
                                {Array.isArray(scan.cveNumbers) && scan.cveNumbers[0] !== "-" ? (
                                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                                    {scan.cveNumbers.length > 0 && (
                                      <>
                                        {/* Display first 3 CVE numbers */}
                                        {scan.cveNumbers.slice(0, 3).map((cve, index) => (
                                          <OverlayTrigger
                                            key={index}
                                            placement="top"
                                            overlay={
                                              <Popover id={`tooltip-${cve}`} className="custom-popover-arrow">
                                                <Popover.Body className="comming-tool-body">
                                                  <label className="comming-text-tool">{cve}</label>
                                                </Popover.Body>
                                              </Popover>
                                            }
                                          >
                                            <a
                                              href={`https://nvd.nist.gov/vuln/detail/${cve}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="hostname-link"
                                            >
                                              <span>{cve}</span>
                                              <ExternalLinkIcon className="ms-1" style={{ width: '12px', height: '12px' }} />
                                            </a>
                                          </OverlayTrigger>
                                        ))}
                                        
                                        {/* Show "+n" for remaining CVEs */}
                                        {scan.cveNumbers.length > 3 && (
                                          <div
                                            className="cve-remaining"
                                            ref={(el) => (portRefs.current[`cve_${scan.port}_${index}`] = el)}
                                            onMouseEnter={() => {
                                              setHoveredCve(`cve_${scan.port}_${index}`);
                                              setShowPopover(true);
                                            }}
                                            onMouseLeave={() => {
                                              setHoveredCve(null);
                                              setShowPopover(false);
                                            }}
                                          >
                                            +{scan.cveNumbers.length - 3}
                                            <Overlay
                                              show={hoveredCve === `cve_${scan.port}_${index}` && showPopover}
                                              target={portRefs.current[`cve_${scan.port}_${index}`]}
                                              placement="top"
                                              rootClose
                                            >
                                              <Popover
                                                id={`popover-cve-${scan.port}-${index}`}
                                                className="custom-popover-arrow cve-popover"
                                              >
                                                <Popover.Body className="comming-tool-body d-grid scrollable-checkbox-list">
                                                  <div>
                                                    {scan.cveNumbers.slice(3).map((cve, i) => (
                                                      <div key={i} style={{ marginBottom: '8px' }}>
                                                        <a
                                                          href={`https://nvd.nist.gov/vuln/detail/${cve}`}
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                          style={{ color: 'white', textDecoration: 'none' }}
                                                        >
                                                         <span style={{color: 'black'}}>{cve}</span>
                                                          <ExternalLinkIcon className="ms-1" style={{ width: '12px', height: '12px' }} />
                                                        </a>
                                                      </div>
                                                    ))}
                                                  </div>
                                                </Popover.Body>
                                              </Popover>
                                            </Overlay>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                ) : "-"}
                              </td>
                              <td>
                                <span className="pe-2 text-white">
                                  {scan?.first_detected
                                    ? formatDate(scan.first_detected)
                                    : "Jan 15, 2025"}
                                </span>
                              </td>
                              <td>
                                <span className="pe-2 text-white">
                                  {scan?.updated_at
                                    ? formatDate(scan.updated_at)
                                    : "-"}
                                </span>
                              </td>
                              <td>
                                {scan?.change_status != null ? (
                                  <CurrentStatus
                                    status={scan?.change_status}
                                    tooltipInfo={scan?.changes}
                                    headerKeys={{
                                      port: "Port",
                                      service: "Service",
                                      product: "Name ",
                                      version: "Version",
                                      is_encrypted: "Encrypted Protocol",
                                      first_detected: "First Detected",
                                      change_status: "Current State",
                                    }}
                                  />
                                ) : (
                                  <CurrentStatus
                                    status={0}
                                    tooltipInfo={{}}
                                    headerKeys={{}}
                                  />
                                )}
                              </td>
                            </tr>
                          );
                        })
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PortsList;
