import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

const BarChart = ({
  barData,
  optionsData,
  marginBottom,
  isChartLabelsOnTop,
  onBarClick,
  dynamicLabels = [],
}) => {
  const getSelectedLabel = (labels, index) => {
    return labels[index] !== undefined ? labels[index] : "Unknown";
  };

  const onChartClick = (event, elements) => {    
    if (elements.length === 0) return;
    const { datasetIndex, index } = elements[0];
    
    // Get the clicked dataset
    const dataset = barData.datasets[datasetIndex];
    
    // For stacked bars, use the dataset label
    if (dataset.label === "Secured" || dataset.label === "Unsecured") {
      return onBarClick && onBarClick(dataset.label === "Secured" ? "Yes" : "No");
    }
    
    // For non-stacked bars (like Risk Score chart), use the dynamic labels
    if (dynamicLabels.length > 0) {
      const selectedLabel = getSelectedLabel(dynamicLabels, index);
      return onBarClick && onBarClick(selectedLabel);
    }
  };

  return (
    <div
      className="bar-chart-container"
      style={{ marginBottom: marginBottom || "3px" }}
    >
      <div className="col-12 d-flex align-items-center justify-content-center h-100">
        <Bar
          data={barData}
          options={{
            ...optionsData,
            onClick: onChartClick,
          }}
          plugins={isChartLabelsOnTop ? [ChartDataLabels] : []}
        />
      </div>
    </div>
  );
};

export default BarChart;
