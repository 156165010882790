import React, { useState, useContext, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ReactComponent as Export } from "../../assets/images/Export.svg";
import axios from "../../util/axios";
import { showToast, showUpdateToast } from "../../util/toasts";
import { ScannerContext } from "../../components/ScannerContext";
import { downloadPDFReport, cleanupDownloads } from "../../util/pdfDownloader";
import { UseServices } from "../../hooks/useServices.js";
import { UseReports } from "../../context/ReportsContext";

const Reports = () => {
  const [controller, setController] = useState(new AbortController());
  const { dropdownItems } = useContext(ScannerContext);
  const { isDone } = UseServices();
  const { downloadingReports, setDownloadingReports, activeDownloads, cleanupDownloads } = UseReports();
  
  // Replace the local loading states with the context values
  const loadingStates = downloadingReports;

  const downloadPDF = async () => {
    try {
      const response = await axios({
        method: "post",
        url: "/generate-pdf",
        data: { html: "<h1>Content</h1>" },
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          const percent = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total || 10000000)
          );
          console.log(`Downloaded: ${percent}%`);
        },
      });

      // Create download link
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.download = "document.pdf";
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed:", error);
    }
  };
  const downloadReport = async (type) => {
    if (loadingStates[type] || !isDone) {
      return; 
    }
    const REQ_URL =
      type === 3
        ? `/generate-securityReport/${dropdownItems[0]?.target_uuid}`
        : "/generate-report";
    const orgName = dropdownItems[0]?.title || "Report";
    const currentDate = new Date();
    const month = currentDate.toLocaleString("default", { month: "long" });
    const year = currentDate.getFullYear();
    const fileName = `${
      type === 3 ? "Security Issues Report" : "Executive Report"
    } - ${orgName} - ${month} ${year}.pdf`;

    await downloadPDFReport({
      url: REQ_URL,
      fileName,
      setLoadingState: (state) => setDownloadingReports((prev) => ({ ...prev, [type]: state })),
      controller,
      activeDownloads,
    });
  };

  // Add cleanup on unmount
  useEffect(() => {
    return () => {
      cleanupDownloads();
    };
  }, []);

  return (
    <div className="">
      <Row>
        <div className="flex justify-content-end mb-2">Reports are relevant only to the organization's target</div>
        <Col xs={6}>
          <div className="report-card-container p-3 ">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 className="report-title m-0">Executive Report</h5>
              <div
                className={`d-flex cursor-pointer ${
                  loadingStates[2] || !isDone ? "disabled-download" : ""
                }`}
                onClick={() => downloadReport(2)}
              >
                <Export className="export-icon" height={23} width={23} />
                <span href="#" className="download-link">
                  Download
                </span>
              </div>
            </div>
            <hr className="m-0 report-header-line" />
            <div className="mx-3">
              <ul className="report-details mb-4 p-3">
                <li>High-level risks and issue overview</li>
                <li>Insights on external exposures</li>
                <li>3-month risk and issue trends</li>
              </ul>
            </div>

            {/* <p className="last-downloaded m-0">
              Last Downloaded:
              { false ? <span> Jan 5, 2025 </span> : <span className="report-not-download"> The report has not been downloaded yet.</span>}
               </p> */}
          </div>
        </Col>
        <Col xs={6}>
          <div className="report-card-container p-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 className="report-title m-0">Security Issue Report</h5>
              <div
                className={`d-flex cursor-pointer ${
                  loadingStates[3] || !isDone ? "disabled-download" : ""
                }`}
                onClick={() => downloadReport(3)}
              >
                <Export className="export-icon" height={23} width={23} />
                <span href="#" className="download-link">
                  Download
                </span>
              </div>
            </div>
            <hr className="m-0 report-header-line" />
            <div className="mx-3">
              <ul className="report-details mb-4 p-3">
                <li>Comprehensive detailed security issues</li>
                <li>Includes risks, assets, and remediations</li>
                <li>Risk scoring breakdown</li>
              </ul>
            </div>
            {/* <p className="last-downloaded m-0">
              Last Downloaded:
              { true ? <span> Jan 5, 2025 </span> : <span className="report-not-download"> The report has not been downloaded yet.</span>}
               </p> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Reports;
