import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/scss/style.scss";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AdminProtectedLayout from "./portal/layout/AdminProtectedLayout";
import ProtectedLayout from "./portal/layout/ProtectedLayout";
import Login from "./auth/login/Login";
import MFA from "./auth/mfa/MFA";
import Forgot from "./auth/forgot/Forgot";
import Login1 from "./auth/login/Login1";
import Register from "./auth/register/Register";
import ResetPassword from "./auth/reset/ResetPassword";
import OTP from "./auth/otp/OTP";
import Auth from "./auth/Auth";
import App from "./App";
import ProtectedRoute from "./util/ProtectedRoute";
import Home from "./portal/home/Home";
import AdminDashboard from "./portal/admin/AdminDashboard";
import LoginAdmin from "./portal/admin/login-pages/LoginPages";
import CyberServicesAdmin from "./portal/admin/cyber-services/CyberServices";
import CyberServicesAdminListing from "./portal/admin/cyber-services/CyberServicesListing";
import SecurityIssuesAdminListing from "./portal/admin/security-issues/SecurityIssues";
import Invitations from "./portal/admin/invitations/Invitations";
import AdminNews from "./portal/admin/news/News";
import AdminUsers from "./portal/admin/users/Users";
import AdminNewsEdit from "./portal/admin/news/Edit";
import AdminUsersEdit from "./portal/admin/users/Edit";
import Leaks from "./portal/leaks/Leaks";
import Spoof from "./portal/spoof/Spoof";
import SpoofCall from "./portal/spoof/SpoofCall";
import SpoofEmail from "./portal/spoof/SpoofEmail";
import News from "./portal/news/News";
import PageNotFound from "./pages/404/NotFound";
import Linky from "./pages/linky/Linky";
import ContactUs from "./portal/contact/ContactUs";
import Settings from "./portal/setting/Settings";
import Dashboard from "./portal/dashboard/Dashboard";
import Waf from "./portal/scanners-in-actions/web-applications/waf/Waf";
import Ports from "./portal/scanners-in-actions/network-security/ports/Ports";
import CVEs from "./portal/scanners-in-actions/cve/cve-scanners/Cve";
import DefaultCredential from "./portal/scanners-in-actions/network-security/default-credential/DefaultCredential";
import AttackSurface from "./portal/attack-surface/AttackSurface";
import Offense from "./portal/offense/Offense";
import CveIssues from "./portal/cve-issues/CveIssues";
import Cms from "./portal/scanners-in-actions/web-applications/cms/Cms";
import Cdn from "./portal/scanners-in-actions/web-applications/cdn/Cdn";
import HostingProvider from "./portal/scanners-in-actions/servers-and-hosts/hosting-provider/HostingProvider";
import Certificates from "./portal/scanners-in-actions/network-security/certificates/Certificates";
import EmailSecurity from "./portal/scanners-in-actions/network-security/email-security/EmailSecurity";
import { ScannerProvider } from "./components/ScannerContext";

// Service Worker
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import "bootstrap-icons/font/bootstrap-icons.css";

import AdminProtectedRoute from "./util/AdminProtectedRoute";
import { PublicRoute } from "./util/PublicRoute";
import CredentialMaker from "./portal/credentials/CredentialMaker";
import Urls from "./portal/urls";
import UrlView from "./portal/urls/UrlView";
import Typography from "./pages/typography/Typography";
import Alerts from "./portal/leaks/Alerts";
import TagManager from "react-gtm-module";
import Easm from "./portal/easm/Easm";
import Easms from "./portal/easm/Easms";
import AssetsIntelligence from "./portal/asset-intelligence/AssetsIntelligence";
import DomainScan from "./portal/asset-intelligence/DomainScan";
import EmptyDomainScan from "./portal/asset-intelligence/EmptyDomain";
import LoginPages from "./portal/scanners-in-actions/web-applications/login-pages/LoginPages";
import CyberServices from "./portal/cyber-services/CyberServices";
import Dns from "./portal/scanners-in-actions/servers-and-hosts/dns/Dns";
import FTPAuthentication from "./portal/scanners-in-actions/network-security/ftp-security/FTP-Authentication";
import ChangePassword from "./portal/change-password/ChangePassword";
import PageExpired from "./pages/expired/Expired";
import { WhatsNew } from "./portal/whatsNew/WhatsNew";
import OrganizationWrapper from "./portal/admin/organizations/OrganizationWrapper";
import LoginPagesSecurity from "./portal/scanners-in-actions/web-applications/login-pages-security/LoginPagesSecurity";
import DataLeaks from "./portal/scanners-in-actions/data-exposure/data-leaks/DataLeaks";
import SensitiveExposedData from "./portal/scanners-in-actions/data-exposure/sensitive-exposed-data/SensitiveExposedData";
import WhatsNewListing from "./portal/admin/whats-new/WhatsNewListing";
import WhatsNewCreate from "./portal/admin/whats-new/WhatsNewCreate";
import { ServicesContextProvider } from "./hooks/useServices";
import { AssetsModalContextProvider } from "./hooks/useAssetsModal";
import HostsSecurity from "./portal/scanners-in-actions/servers-and-hosts/hosts-security/HostsSecurity";
import { ReportsProvider } from "./context/ReportsContext";

const tagManagerArgs = {
  gtmId: "G-22XXWSSJS3",
  // config: 'G-22XXWSSJS3'
};

TagManager.initialize(tagManagerArgs);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <ReportsProvider>
    <ServicesContextProvider>
      <AssetsModalContextProvider>
        <BrowserRouter basename={"/"}>
          <ScannerProvider>
            <Routes>
              <Route path="/auth" element={<Auth />}>
                <Route
                  path="login"
                  element={
                    <PublicRoute>
                      <Login />
                    </PublicRoute>
                  }
                />
                <Route
                  path="mfa"
                  element={
                    <PublicRoute>
                      <MFA />
                    </PublicRoute>
                  }
                />
                <Route
                  path="forgot-password"
                  element={
                    <PublicRoute>
                      <Forgot />
                    </PublicRoute>
                  }
                />
                <Route
                  path="login1"
                  element={
                    <PublicRoute>
                      <Login1 />
                    </PublicRoute>
                  }
                />
                <Route path="register">
                  <Route
                    path=":code"
                    element={
                      <PublicRoute>
                        <Register />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path=""
                    element={
                      <PublicRoute>
                        <Register />
                      </PublicRoute>
                    }
                  />
                </Route>
                <Route
                  path="otp/:code"
                  element={
                    <PublicRoute>
                      <OTP />
                    </PublicRoute>
                  }
                />
                <Route
                  path="reset-password/:code"
                  element={
                    <PublicRoute>
                      <ResetPassword />
                    </PublicRoute>
                  }
                />
              </Route>
              <Route
                path="/admin"
                element={
                  <ProtectedRoute>
                    <AdminProtectedRoute>
                      <AdminProtectedLayout>
                        <App />
                      </AdminProtectedLayout>
                    </AdminProtectedRoute>
                  </ProtectedRoute>
                }
              >
                <Route path="" element={<AdminDashboard />} />
                <Route path="invitations" element={<Invitations />} />
                <Route path="organizations" element={<OrganizationWrapper />} />
                <Route path="news">
                  <Route path="" element={<AdminNews />} />
                  <Route path=":type" element={<AdminNews />} />
                  <Route path="edit/:id" element={<AdminNewsEdit />} />
                </Route>
                <Route path="login-pages">
                  <Route path="" element={<LoginAdmin />} />
                </Route>
                <Route path="security-issues">
                  <Route path="" element={<SecurityIssuesAdminListing />} />
                </Route>
                <Route path="cyber-services">
                  <Route path="" element={<CyberServicesAdminListing />} />
                  <Route path="create" element={<CyberServicesAdmin />} />
                </Route>
                <Route path="whats-new">
                  <Route path="" element={<WhatsNewListing />} />
                  <Route path="create" element={<WhatsNewCreate />} />
                </Route>
                <Route path="users">
                  <Route path="" element={<AdminUsers />} />
                  <Route path=":type" element={<AdminNews />} />
                  <Route path="edit/:id" element={<AdminUsersEdit />} />
                </Route>
              </Route>

              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <ProtectedLayout>
                      <App />
                    </ProtectedLayout>
                  </ProtectedRoute>
                }
              >
                <Route path="" element={<Home />} />
                <Route path="typography" element={<Typography />} />
                <Route path="/dashboard">
                  <Route path=":target_id" element={<Dashboard />} />
                  <Route path="alerts" element={<Alerts />} />
                </Route>
                <Route path="/security-issues">
                  <Route path=":id" element={<CveIssues />} />
                  <Route path="alerts" element={<Alerts />} />
                </Route>
                <Route path="/attack-surface">
                  <Route path="" element={<AttackSurface />} />
                  <Route path="alerts" element={<Alerts />} />
                </Route>
                <Route path="/offense">
                  <Route path=":id" element={<Offense />} />
                  <Route path="alerts" element={<Alerts />} />
                </Route>
                <Route path="/cyber-services">
                  <Route path="" element={<CyberServices />} />
                  <Route path="alerts" element={<Alerts />} />
                </Route>
                <Route path="/scanner/:target_id/waf" element={<Waf />} />
                <Route
                  path="/scanner/:target_id/hosts-security"
                  element={<HostsSecurity />}
                />
                <Route path="/scanner/:target_id/ports" element={<Ports />} />
                <Route path="/scanner/:target_id/cve" element={<CVEs />} />
                <Route
                  path="/scanner/:target_id/ssh-credential"
                  element={<DefaultCredential />}
                />
                <Route
                  path="/scanner/:target_id/ftp-credential"
                  element={<FTPAuthentication />}
                />
                <Route
                  path="/scanner/:target_id/email-security"
                  element={<EmailSecurity />}
                />
                <Route
                  path="/scanner/:target_id/certificates"
                  element={<Certificates />}
                />
                <Route path="/scanner/:target_id/cdn" element={<Cdn />} />
                <Route path="/scanner/:target_id/cms" element={<Cms />} />
                <Route
                  path="/scanner/:target_id/hosting-provider"
                  element={<HostingProvider />}
                />
                <Route
                  path="/scanner/:target_id/data-leaks"
                  element={<DataLeaks />}
                />
                <Route
                  path="/scanner/:target_id/sensitive-exposed-data"
                  element={<SensitiveExposedData />}
                />
                <Route
                  path="/scanner/:target_id/login-pages-security"
                  element={<LoginPagesSecurity />}
                />
                <Route
                  path="/scanner/:target_id/login-page"
                  element={<LoginPages />}
                />
                <Route path="/scanner/:target_id/dns" element={<Dns />} />
                <Route path="leaks">
                  <Route path="" element={<Leaks />} />
                  <Route path="alerts" element={<Alerts />} />
                </Route>
                <Route path="spoof">
                  <Route path="" element={<Spoof />} />
                  <Route path="phone" element={<SpoofCall />} />
                  <Route path="email" element={<SpoofEmail />} />
                </Route>
                <Route path="news" element={<News />} />
                <Route path="credentials" element={<CredentialMaker />} />
                <Route path="urls" element={<Urls />} />
                <Route path="urls/view/:id" element={<UrlView />} />
                {/* Scanner... */}
                <Route path="easm">
                  <Route path="" element={<Easms />} />
                  <Route path="new" element={<Easm />} />
                  <Route path=":id" element={<Easm />} />
                </Route>
                <Route path="/:target_id" element={<Dashboard />} />
                <Route path="assets-inventory">
                  <Route path="new" element={<DomainScan />} />
                  <Route path="add" element={<EmptyDomainScan />} />
                  <Route path=":id/edit" element={<DomainScan />} />
                  <Route path=":id" element={<AssetsIntelligence />} />
                  <Route path="" element={<AssetsIntelligence />} />
                </Route>
                <Route path="contact" element={<ContactUs />} />
                <Route path="settings">
                  <Route path="" element={<Settings />} />
                  <Route path="license" element={<Settings />} />
                </Route>
                <Route path="change-password" element={<ChangePassword />} />
                <Route path="whats-new" element={<WhatsNew />} />
              </Route>
              <Route path="/url/:code" element={<Linky />} />
              <Route path="/404" element={<PageNotFound />} />
              <Route path="/expired" element={<PageExpired />} />
              <Route path="*" element={<Navigate to="/404" />} />
            </Routes>
          </ScannerProvider>
        </BrowserRouter>
      </AssetsModalContextProvider>
      </ServicesContextProvider>
    </ReportsProvider>
  </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
