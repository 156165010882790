import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  Dropdown,
  Spinner,
  OverlayTrigger,
  Popover,
  Tooltip,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Edit } from "../../assets/images/edit.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/chevron-green-down.svg";
import { useParams } from "react-router-dom";
import { ReactComponent as Search } from "../../assets/images/search.svg"; // Make sure to add a search icon SVG
import { getUserRole } from "../../util/userUtils";

const BaseDropdown = ({
  items,
  selectedItem,
  onSelect,
  title,
  canAddTarget = false,
  isDropdownOpen,
  setIsDropdownOpen,
  hasSearch,
  updateOrganization
}) => {
  const { id } = useParams();
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const isViewUser = user?.member_level === 0;
  const [isLoading,setIsLoading] = useState(false)
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const userRole = getUserRole();
  useEffect(() => {
    if (textRef.current) {
      setIsTruncated(textRef.current.scrollWidth > textRef.current.offsetWidth);
    }
  }, [selectedItem, title]);

  const popover = (
    <Popover id="popover-basic-comming">
      <Popover.Body className="comming-tool-body">
        <label className="comming-text-tool">
          {selectedItem ? selectedItem.title : title}
        </label>
      </Popover.Body>
    </Popover>
  );

  const renderTooltip = (props, title) => (
    <Tooltip
      id="selectedValue-tooltip"
      {...props}
      className="light-theme-base-tooltip"
    >
      {title}
    </Tooltip>
  );

  const filteredItems = items.filter((item) =>
    item?.org_name?.toLowerCase().includes(searchInput?.toLowerCase())
  );

  const renderDropdownItems = useMemo(() => {
    const list = hasSearch ? filteredItems : items;
    if (!list.length) {
      return (
        <Dropdown.Item disabled className="text-center text-muted">
          No results found
        </Dropdown.Item>
      );
    }
  
    return list.map((item) => {
      const itemName = item.org_name || item.title;
  
      return (
        <Dropdown.Item
          className="target-hover"
          style={{ padding: "6px 20px", display: "flex", alignItems: "center", justifyContent: "space-between" }}
          as={Link}
          key={item.id}
          to={!hasSearch  ? `/dashboard/${item.target_uuid}` : undefined}

          onClick={async () => {
            setIsDropdownOpen(false);
            onSelect(item);
            if(updateOrganization) {
              setIsLoading(true);
              await updateOrganization(item.id);
              setIsLoading(false);
            }
            if (item?.target_uuid) {
              navigate(`/dashboard/${item.target_uuid}`,{ replace: true });
            } else if (canAddTarget) {
              navigate(`/assets-inventory/new`, { replace: true });
            } else {
              navigate(`/assets-inventory`, { replace: true });
            }
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              {itemName?.length > (item?.default_scan === 1 ? 18 : 25) ? (
                <OverlayTrigger placement="top" overlay={(props) => renderTooltip(props, itemName)}>
                  <div>{`${itemName.slice(0, item?.default_scan === 1 ? 18 : 25)}...`}</div>
                </OverlayTrigger>
              ) : (
                itemName
              )}
            </div>
          </div>
          {item?.default_scan === 1 && <tag className="target-default">Organization</tag>}
          {!isViewUser && (
            <Link to={`/assets-inventory/${item.target_uuid}/edit`} className="add-button header-title">
              <Edit className="edit-icon" />
            </Link>
          )}
        </Dropdown.Item>
      );
    });
  }, [items, filteredItems, hasSearch]);
  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" size="sm" />
      </div>
    );
  }
  return (
    <Dropdown
      show={isDropdownOpen}
      onToggle={() => setIsDropdownOpen(!isDropdownOpen)}
      className="border-0 bg-transparent2 w-97 target-dropdown-main"
      data-bs-theme="dark"
    >
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        className="target-dropdown d-flex w-100 align-items-center justify-content-between sidebar-dp-height"
        style={{
          background: "#0A0A0E",
          borderColor: hasSearch ? "white" : "#398FFE",
          borderRadius: "5px",
        }}
      >
        <div className="d-flex align-items-center">
          <div className="dp-selected-item d-flex">
            <OverlayTrigger
              trigger={isTruncated ? ["hover", "focus"] : []}
              placement="bottom"
              overlay={popover}
            >
              <span
                ref={textRef}
                className={`text-truncate ${
                  selectedItem?.default_scan !== 1 ? "flex-grow-1" : ""
                }`}
              >
                {selectedItem && selectedItem.title}
                {!selectedItem && title}
              </span>
            </OverlayTrigger>
            {selectedItem?.default_scan === 1 && (
              <tag className="target-default">Organization</tag>
            )}
          </div>
        </div>
        <ArrowDown
          height={18}
          width={18}
          className={`base-drop-down-arrow ${isDropdownOpen ? "open" : ""}`}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="w-100 mt-2 p-0"
        style={{
          background: "#0A0A0E",
          borderColor: "#398FFE",
          padding: "13px 20px",
          maxHeight: 500,
          overflowY: "auto",
        }}
      >
        {hasSearch && (
          <div
            className="position-relative w-100"
            style={{ padding: "0px 5px 0px 9px" }}
          >
            <span
              className="position-absolute top-50 start-0 translate-middle-y ps-3 text-gray-400"
              style={{ margin: "0px 5px 2px 5px" }}
            >
              <Search className="w-5 h-5" />
            </span>

            <input
              type="text"
              className="form-control search-input-sidebar"
              placeholder="Search..."
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
                setIsDropdownOpen(true);
              }}
              onBlur={() => setTimeout(() => setIsDropdownOpen(false), 200)}
            />
          </div>
        )}
        {renderDropdownItems}

        <hr className="m-0 p-0" />
        {canAddTarget &&  (userRole === "distributor" ||
          userRole === "editor" ||
          userRole === "admin")  ? (
          <Dropdown.Item as={Link} to="/assets-inventory/new">
            <div
              className="d-flex align-items-center add-button header-title p-3 gap-2"
              style={{ marginLeft: "-4px" }}
            >
              <i className="bi bi-plus-circle "></i>{" "}
              <span className="ml-5 p-0 fw-lighter">Add New</span>
            </div>
          </Dropdown.Item>
        ) : null}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default BaseDropdown;
