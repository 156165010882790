import { toast } from "react-toastify";
import { ReactComponent as XmarkCricle } from "../assets/images/Xmark-circle.svg";
import { ReactComponent as CheckCricle } from "../assets/images/check-circle.svg";


export const showToast = (
  message,
  type,
  position = "top-center",
  theme = "light",
  options = {},
  autoClose = 1500
) => {
  const isError = type === "error";
  const isSuccess = type === "success";
  const icon = isError
  ? <XmarkCricle className="h-auto w-auto" />
  : isSuccess
  ? <CheckCricle className="h-auto w-auto" />
  : null;
  const toastId = toast[type](message, {
    position,
    theme,
    autoClose,
    icon,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    ...options,
    className: `${options.className || ""} ${isSuccess ? "toast-success" : ""}`
  });

  return toastId;
};

export const showUpdateToast = (
  toasterId,
  render,
  type,
  isLoading,
  options = {}
) => {
  const isError = type === "error";
  const isSuccess = type === "success";
  const icon = isError
  ? <XmarkCricle className="h-auto w-auto" />
  : isSuccess
  ? <CheckCricle className="h-auto w-auto" />
  : null;
  toast.update(toasterId, {
    render,
    type,
    isLoading,
    autoClose: 1500,
    icon,
    hideProgressBar: false,
    draggable: false,
    ...options,
    className: `${options.className || ""} ${isSuccess ? "toast-success" : ""}`

  });
};

export const closeToast = (id) => {
  toast.dismiss(id);
};

export const showPromiseToast = (data, render, options = {}) => {
  toast.promise(data, render, (options = {}));
};
export const ToastContent = ({ title, subtitle }) => {
  return (
    <div>
      <div className="toast-title">{title}</div>
      <div className="toast-subtitle">{subtitle}</div>
    </div>
  );
};

